import { PadBox } from "np-platform-client/component/basics";
import { ReactionButton } from "np-platform-client/component/button";
import { useCollection, useDatastore, usePersonaKey } from "np-platform-client/util/datastore";
import { logEventAsync } from "np-platform-client/util/eventlog";
import { useConfig } from "np-platform-client/util/features";
import { StyleSheet, View } from "react-native";

export const AutoModeratorReactionTypesFeature = {
    name: 'AutoModerator Reaction Types',
    key: 'automoderatorreactiontypes',
    config: {
        commentBelowWidgets: [AutoModeratorReactionTypesFeature]
    },
    defaultConfig: {
        
    }
}


export function countReactionsPerType(reactions) {
    const counts = {};
    reactions.forEach(reaction => {
        const {reactionType} = reaction;
        counts[reactionType] = counts[reactionType] || 0;
        counts[reactionType]++;
    })
    return counts;
}

export function AutoModeratorReactionTypesWidget({thoughtProvokingPrompt}) {
    const s = ReactionTypesWidgetsStyle;
    const {reactionTypes} = useConfig();
    const personaKey = usePersonaKey();
    const datastore = useDatastore();
    const thoughtProvokingPrompts = useCollection('thought_provoking_prompt',{sortBy:"time",reverse:true})//TODO: Check if we can just pass the latest instead of going fetching them here
    const isNewest = thoughtProvokingPrompts[0].key === thoughtProvokingPrompt.key;
    const reactions = useCollection('thought_provoking_prompt_reactions', {filter: {reactTo: thoughtProvokingPrompt.key}});
    const myReactions = useCollection('thought_provoking_prompt_reactions', {filter: {reactTo: thoughtProvokingPrompt.key, from: personaKey}});

    const reactionCounts = countReactionsPerType(reactions);
    const myReactionCounts = countReactionsPerType(myReactions);

    function onReact({reaction}) {
        if (myReactionCounts[reaction.label]) {
            datastore.deleteObject(
                "thought_provoking_prompt_reactions",
                myReactions.find((r) => r.reactionType == reaction.label).key
            );

            logEventAsync(datastore, "conversationhelper-reaction-undo", {
                reaction: reaction.label,
                reminder: thoughtProvokingPrompt.reminder,
                suggestedPerspective: thoughtProvokingPrompt.suggestedPerspective,
            });
        } else {
            if (reaction.single) {
                const existing = datastore.getCollection("thought_provoking_prompt_reactions", {
                    filter: { from: personaKey, reactionType: reaction.label },
                });
                existing.forEach(r => datastore.deleteObject('reaction', r.key));
            }

            datastore.addObject("thought_provoking_prompt_reactions", {
                reactTo: thoughtProvokingPrompt.key,
                from: personaKey,
                reactionType: reaction.label,
            });

            logEventAsync(datastore, "conversationhelper-reaction", {
                reaction: reaction.label,
                reminder: thoughtProvokingPrompt.reminder,
                suggestedPerspective: thoughtProvokingPrompt.suggestedPerspective,
            });
        }
    }

    const shownReactionTypes = reactionTypes.filter(reaction => 
        isNewest || reactionCounts[reaction.label] > 0
    );

    return <View style={s.outer}>
        {shownReactionTypes.map((reaction, i) => 
           <PadBox key={i} right={!isNewest ? 24 : 16} top={16}>
                <ReactionButton onPress={datastore.needsLogin(() => onReact({reaction}), 'react')} 
                    emoji={reaction.emoji}
                    label={reaction.label} 
                    selected={myReactionCounts[reaction.label] > 0}
                    count={reactionCounts[reaction.label] || 0}
                    viewOnly={!isNewest}
                />
            </PadBox>
        )}
    </View>
}
const ReactionTypesWidgetsStyle = StyleSheet.create({
    outer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    }
})



