import { CTAButton } from "np-platform-client/component/button";
import { HumanModerationModal, ModerationNote, sendPostToModeration, showModalAfterPosting } from "../feature/moderation/HumanReviewsEverythingFeature"
import { StructureDemo } from "np-platform-client/util/instance"
import { useState } from "react";
import React from "react";
import { BasicComments, Comment, Composer, EditComment } from "np-platform-client/component/comment";
import { CLICK, POPUP } from "np-platform-client/component/demo";
import { View } from "react-native-web";
import { Pad } from "np-platform-client/component/basics";
import { INPUT } from "np-platform-client/system/demo";

export const DemoFallbacksFeature = {
    key: 'demo_fallbacks',
    name: 'Demo - Fallbacks',
    config: {
        componentSections: [
            {label: 'Moderation', key: 'moderation', pages: [
                {
                    label: 'Human Review Everything',
                    key: 'human_moderate_all',
                    designUrl: 'https://www.figma.com/design/kf97PnjRjvYiMosLYnItoF/Graceful-Fallbacks---Testing?node-id=63-200&node-type=canvas&m=dev',
                    storySets: fallbacksStorySets
                }
            ]}
        ],
        featureSections: [
            {label: 'Question', key: 'conversationfeatures', pages: [
                {label: 'Human Reviews Everything', key: 'fallbacks', screen: HumanPreModerationFullDemo},
            ]},
        ]
    }
}

function ModalButton({modal}) {
    const [open, setOpen] = useState(false);
    return <>
        <CTAButton onPress={() => setOpen(true)} label='Open Modal'/>
        {open && React.createElement(modal, {onClose: () => setOpen(false)})}
    </>
}



function fallbacksStorySets() {return [
    {
        label: 'Moderation Note',
        content: <ModerationNote />   
    },
    {
        label: 'Moderation Modal',
        content: <ModalButton modal={HumanModerationModal} />,
        stories: [
            {label: 'Open Modal', actions:[
                CLICK('Open Modal'),
            ]},
            {label: 'Open and Close Modal', actions:[
                CLICK('Open Modal'),
                POPUP(CLICK('I understand'))
            ]},
        ]
    },
    {
        label: 'Moderation Composer',
        structureKey: 'question',
        features: ['premoderation', 'human_premoderation', 'hide_rejected'],
        serverCall: {moderation: {
            sendToReview: () => {}
        }},    
        personaKey: 'a',
        content: <Composer goBackAfterPost />,
        stories: [
            {label: 'Post Comment', actions:[
                INPUT('comment-edit', 'Elvis lives'),
                CLICK('Post'),
                POPUP(CLICK('I understand'))
            ]}
        ]
    },
    {
        label: 'Comment in Review',
        collections: {comment: [
            {key: 'a', from: 'a', text: 'Is Godzilla Good?', inReview: true}
        ]},
        structureKey: 'question',
        features: ['hide_rejected'],
        content: <Comment commentKey='a' />
    }
]}

const globals = {
    name: 'Is Godzilla Good?',
    questionAuthor: 'a',
    questionTime: Date.now(),
}


function HumanPreModerationFullDemo() {
    return <StructureDemo globals={globals} structureKey='question' features={{human_premoderation: true}} />
}


