import { HeaderBox, HorizBox, Pad, spacings } from "np-platform-client/component/basics";
import { IconButton, SubtleButton } from "np-platform-client/component/button";
import { colorPinkBackground, colorRed, colorLightBlueBackground, colorWhite } from "np-platform-client/component/color";
import { UtilityText, Heading } from "np-platform-client/component/text";
import { Notification, NotificationFilled, Time, UserMultiple } from "@carbon/icons-react";
import { StyleSheet, View } from "react-native";
import { formatToDateRangeString } from "./date";

export function TopicHeaderBox({status, preHeading, title, content}) {
    return (
        <HeaderBox horiz={spacings.lg} vert={spacings.xl2} backgroundColor={colorLightBlueBackground}>
            {status && (status)}
            <Pad size={spacings.xl} />
            {preHeading && (
                <>
                    <UtilityText label={preHeading} />
                    <Pad size={spacings.sm} />
                </>
            )}
            <Heading level={1} label={title} />
            <Pad size={spacings.xl} />
            {content && (content)}
        </HeaderBox>
    )
}

export function TopHeaderBar({tag, notificationSubscribed, notificationOnPress}) {
    return (
        <HorizBox spread>
            {tag && (tag)}
            <HorizBox>
                <MockTopicFollowButton notificationSubscribed={notificationSubscribed} onPress={notificationOnPress} />
            </HorizBox>
        </HorizBox>
    )
}

export function TopicLiveStatus({live = false}) {
    const s = topicTagStyle;
    const label = live ? 'Live' : 'Ended';

    return (
        <View style={s.tag}>
            {live && (
                <>
                    <View style={s.outerDot}>
                        <View style={s.dot} />
                    </View>
                    <Pad size={spacings.xxs} />
                </>
            )}
            <UtilityText weight='medium' type='tiny' label={label} />
        </View>
    )
}

const topicTagStyle = StyleSheet.create({
    outerDot: {
        width: 14,
        height: 14,
        backgroundColor: `${colorRed}20`,
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: spacings.xxs,
    },
    dot: {
        width: 8,
        height: 8,
        backgroundColor: colorRed,
        borderRadius: '50%',
    },
    tag: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        padding: spacings.xxs,
        paddingRight: 8,
        borderRadius: 100,
        backgroundColor: colorPinkBackground,
    },
})

export function MockTopicFollowButton({notificationSubscribed = false, onPress}) {
    const notificationIcon = notificationSubscribed ? NotificationFilled : Notification;
    const label = notificationSubscribed ? 'Following' : 'Follow';
    return (
        <SubtleButton color={colorWhite} icon={notificationIcon} label={label} onPress={onPress}/>
    )
}

export function TopicParticipants({count, startAt, endAt}) {
    const currentDate = new Date();
    const endDate = new Date(endAt);
    const topicIsActive = currentDate.getTime() < endDate.getTime()
    if (topicIsActive) {
        return (<ActiveUsers count={count} />)
    } else {
        return (<TopicDate date={formatToDateRangeString({ start: new Date(startAt), end: new Date(endAt), locale: 'fr-CA' })} />)
    }
}

export function ActiveUsers({count = 0}) {
    const formatParams = {count: count, singular: 'participant', plural: 'participants'};
    return (
        <HorizBox>
            <UserMultiple style={participantsStyle.icon} />
            <UtilityText label='{count} {noun}' formatParams={formatParams} weight='medium' type='tiny' />
        </HorizBox>
    )
}

export function TopicDate({date}) {
    return (
        <HorizBox>
            <Time style={participantsStyle.icon} />
            <UtilityText label={date} weight='medium' type='tiny' />
        </HorizBox>
    )
}

const participantsStyle = StyleSheet.create({
    icon: {
        marginRight: spacings.xs,
    },
})
