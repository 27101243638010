import { HorizBox, Pad, PadBox } from 'np-platform-client/component/basics';
import { colorBlack, colorGreyBorder, colorGreyHover, colorGreyPopupBackground, colorWhite } from 'np-platform-client/component/color';
import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import { View, Animated, StyleSheet, PanResponder, Dimensions, TouchableWithoutFeedback, Pressable } from 'react-native';
import { ColoredBall, ColoredBallPill } from './colorpill';
import { DataVizText, UtilityText } from 'np-platform-client/component/text';

// A slider component that snaps to the nearest stop point when released.
// A value of zero means that nothing has been selected, and the handle will be hidden.

export function SliderWithStopPoints({title, numberOfStops, optionLabels, optionColors, value, isDefault=false, onChangeValue}) {
    const s = SliderWithStopPointsStyle;
    const [componentWidth, setComponentWidth] = useState(null);
    const xPosition = useRef(new Animated.Value(0)).current;
    const [hasLayout, setHasLayout] = useState(false);
    const [showHover, setShowHover] = useState(false);
    const [hoverValue, setHoverValue] = useState(0);
    const [hoverPosition, setHoverPosition] = useState(0);
    const [hasDragged, setHasDragged] = useState(false);
    const previousValue = useRef(value);
    const barRef = useRef(null);

    function getSpaceBetweenStops() {
        const rect = barRef.current.getBoundingClientRect();
        return (rect.width - 8) / (numberOfStops - 1);
    }

    function valueToXPosition(value) {
        const stopWidth = getSpaceBetweenStops();
        return (value - 1) * stopWidth - 10;
    }

    function screenXPositionXOffset(xPosition) {
        const rect = barRef.current.getBoundingClientRect();
        return xPosition - rect.left;
    }

    function xPositionToValue(xPosition) {
        const stopWidth = getSpaceBetweenStops();
        const unclippedValue = Math.round((xPosition + 10) / stopWidth) + 1;
        return Math.max(1, Math.min(numberOfStops, unclippedValue));
    }

    useEffect(() => {
        const newXPosition = valueToXPosition(value);
        xPosition.flattenOffset();

        if (previousValue.current > 0 && previousValue.current != value) {
            Animated.timing(xPosition, {
                toValue: newXPosition, duration: 300, useNativeDriver: false, 
            }).start();
        } else {
            xPosition.setValue(newXPosition);
        }
        previousValue.current = value;
    }, [value, componentWidth]);

    const panResponder = PanResponder.create({
        onStartShouldSetPanResponder: () => true,
        onMoveShouldSetPanResponder: () => true,
        onPanResponderGrant: () => {
            xPosition.flattenOffset();
        },
        onPanResponderMove: (_, gestureState) => {
            const dragX = screenXPositionXOffset(gestureState.moveX) - 10;
            const rect = barRef.current.getBoundingClientRect();
            const clippedX = Math.max(-10, Math.min(rect.width - 10, dragX));
            xPosition.setValue(clippedX);
            setHasDragged(true);
            setShowHover(true);
            const newHoverValue = xPositionToValue(dragX);
            setHoverValue(newHoverValue);
            setHoverPosition(valueToXPosition(newHoverValue))
        },
        onPanResponderRelease: (_, gestureState) => {
            xPosition.flattenOffset();
            const pressX = screenXPositionXOffset(gestureState.moveX);
            const newValue = xPositionToValue(pressX);
            const newXPosition = valueToXPosition(newValue);
            Animated.timing(xPosition, {
                toValue: newXPosition, duration: 300, useNativeDriver: false,
            }).start();
            onChangeValue(newValue);
            setShowHover(false);
        },
    })
  
    const handleBarPress = (e) => {
        if (componentWidth > 0 && barRef.current) {
            const pressX = screenXPositionXOffset(e.nativeEvent.pageX);
            const newValue = xPositionToValue(pressX);
            onChangeValue(newValue);
        }
    };
    
    const handleLayout = (event) => {
        const {width} = event.nativeEvent.layout;
        setComponentWidth(width);
        xPosition.setValue((value - 1) * (width / (numberOfStops - 1)) - 16);
        setHasLayout(true);
    };
    
    return (
        <View>
            <View>
                <DataVizText type='heading2' label={title} />
                <Pad size={12} />
                {showHover && <View style={s.overlapBar}>
                    <HoverHelpBar label={optionLabels[hoverValue-1]} 
                        color={optionColors[hoverValue-1]} 
                        hoverValue={hoverValue} numberOfStops={numberOfStops}
                        xPosition={hoverPosition + 10} />
                </View>}
            </View>
            <Pressable onPress={handleBarPress} ref={barRef}>
                <View style={s.container} onLayout={handleLayout}>
                    {value > 0 && hasLayout && <Animated.View
                        {...panResponder.panHandlers}
                        style={[s.dragHandle, 
                            {transform: [{ translateX: xPosition }]},
                            (isDefault && !hasDragged) ? {opacity: 0.5} : {opacity: 1},
                            (showHover ? s.dragHandleShadow : null)
                        ]}
                    >
                        <SixDiceIcon color={colorGreyBorder} />
                    </Animated.View>}
                    <PadBox horiz={4}>
                        <HorizBox spread center>
                            {Array(numberOfStops).fill().map((_,idx) =>
                                <View key={idx} style={s.selectionStopPoint} />
                            )}          
                        </HorizBox>
                    </PadBox>
                </View>
            </Pressable>
        </View>
    );
};
  
const SliderWithStopPointsStyle = StyleSheet.create({
    container: {
        width: '100%',
        height: 8,
        borderRadius: 4,
        marginVertical: 16,
        backgroundColor: colorGreyHover,
        justifyContent: 'center',
    },
    overlapBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 8,
    },
    dragHandle: {
        width: 20,
        height: 40,
        borderRadius: 16,
        backgroundColor: 'white',
        position: 'absolute',
        borderColor: colorGreyBorder,
        borderWidth: 1,
        // boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.30)',
        cursor: 'pointer',
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    dragHandleShadow: {
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.30)',
    },
    selectionStopPoint: {
        width: 6,
        height: 6,
        borderRadius: 4,
        backgroundColor: colorWhite,
        borderColor: colorGreyPopupBackground,
    },
});
  

function SixDiceIcon({color}) {
    return <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="1" fill={color} />
        <circle cx="6" cy="2" r="1" fill={color} />
        <circle cx="10" cy="2" r="1" fill={color} />

        <circle cx="2" cy="6" r="1" fill={color} />
        <circle cx="6" cy="6" r="1" fill={color} />
        <circle cx="10" cy="6" r="1" fill={color} />

        <circle cx="2" cy="10" r="1" fill={color} />
        <circle cx="6" cy="10" r="1" fill={color} />
        <circle cx="10" cy="10" r="1" fill={color} />
    </svg>
}

function HoverHelpBar({label, color, xPosition, hoverValue, numberOfStops}) {
    const s = HoverHelpBarStyle;
    const translation = hoverValue == 1 ? 0 : hoverValue == numberOfStops ? '-100%' : '-50%';

    return <View style={s.outer}>
        <View style={[s.hoverPill, {left: xPosition, transform: [{translateX: translation}]}]}>
            <ColoredBall color={color} />
            <Pad size={8} />
            <UtilityText weight='medium' type='tiny' label={label} color={colorWhite} />
        </View>
    </View>
}

const HoverHelpBarStyle = StyleSheet.create({
    label: {
        color: colorWhite,
    },
    outer: {
        position: 'absolote',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    hoverPill: {
        backgroundColor: colorBlack,
        borderRadius: 8,
        padding: 8,
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 0,
        flexShrink: 1,
        alignSelf: 'flex-start',
    }
})