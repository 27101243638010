const videoVotingEventTypes = {
    'videovoting-start-video': 'User started a video that has video voting',
    'videovoting-change-anonymity': 'User enabled or disabled anonymous voting for a video',
    'videovoting-change-local-setting': 'User changed the video voting setting for a video',
    'videovoting-navigate-to-user-settings': 'User navigated to the user settings',
    'videovoting-change-global-setting': 'User changed their global video voting setting',
    'videovoting-vote': 'User selected an option',
    'videovoting-revoke-vote': 'User revoked their vote',
    'videovoting-skip-remaining-time': 'User skipped the remaining time in the voting dialog',
    'videovoting-time-up': 'Time in the voting dialog expired',
    'videovoting-navigate-to-discussion': 'User navigated to the conversation'
}

const tabNavigationEventTypes = {
    'tabnavigation-open': 'User opened a tab navigation',
    'tabnavigation-switch-tab': 'User switched to a different tab in the tab navigation'
}

const conversationHelperEventTypes = {
    'conversationhelper-view-history': 'User opened Conversation Helper history',
    'conversationhelper-close-history': 'User closed Conversation Helper history',
    'conversationhelper-update-guidance' : 'Conversation Helper published a new thought-provoking prompt',
    'conversationhelper-analyze-conversation': 'Coversation Helper analyzed the comment section for problems',
    'conversationhelper-comment-feedback': 'Conversation Helper analyzed a comment and gave private feedback if applicable',
    'conversationhelper-generate-summary': 'Conversation Helper generated a summary of the conversation',
    'conversationhelper-reaction': "User reacted to Conversation Helper's guidance",
    'conversationhelper-reaction-undo': "User undid a reaction to Conversation Helper's guidance",
}

const accordionFieldEventTypes = {
    'accordionfield-open': 'User opened an accordion field',
    'accordionfield-close': 'User closed an accordion field',
}

const blacklistEventTypes = {
    'blacklist-add-terms': 'User added new term(s) to the blacklist',
    'blacklist-delete-terms': 'User deleted term(s) from the blacklist'
}

// TODO: Event types should be added per-module, not globally
export const newEventTypes = {
    'teaser-show': 'Teaser shown on a content page',
    'question-view': 'View a question conversation',
    'teaser-click': 'User clicked on a conversation in a teaser',
    'comment-slider-pick': 'User selected a position on comment slider',
    'reaction': 'User reacted to a post',
    'reaction-undo': 'User undid a reaction',
    'moderation-block': 'Comment blocked by moderation',
    'moderation-modal': 'User opened the moderation modal',
    'moderation-appeal': 'User submitted a moderation appeal',
    ...videoVotingEventTypes,
    ...tabNavigationEventTypes,
    ...conversationHelperEventTypes,
    ...accordionFieldEventTypes,
    ...blacklistEventTypes
}

