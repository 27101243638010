import { Narrow } from "np-platform-client/component/basics"
import { CLICK, DemoSection } from "np-platform-client/component/demo"
import { Datastore } from "np-platform-client/util/datastore";
import { VideoVotingOverview, VotingResults } from "../../../feature/zdf/videovoting/VideoVotingOverviewFeature";
import { StructureDemo } from "np-platform-client/util/instance";
import { videoVotingDemoData } from "./videovotingdemo.util";


const templateIndividual = videoVotingDemoData.templateIndividual;
const templateConnected = videoVotingDemoData.templateConnected;

const serverCall = {
    eventlog: {
        logEvent: () => { },
    },
    videovoting: {
        getVideoVotingTemplate: () => {
            return [{
                data: templateIndividual,
                votingInstanceKey: "voting-0",

            }, {
                data: templateConnected,
                votingInstanceKey: "voting-1",

            }]
        }
    }
}

const backlinkCollection = [
    {
        title: "Individual questions",
        key: "voting-0",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    },
    {
        title: "Connected questions",
        key: "voting-1",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    }
]

const votesCollectionIndividual = [
    {
        key: "vi0",
        questionKey: templateIndividual.questions[0].key,
        optionKey: templateIndividual.questions[0].options[0].key,
        instanceKey: "voting-0",
        from: "b"
    },
    {
        key: "vi1",
        questionKey: templateIndividual.questions[0].key,
        optionKey: templateIndividual.questions[0].options[0].key,
        instanceKey: "voting-0",
        from: "a"
    }, {
        key: "vi2",
        questionKey: templateIndividual.questions[0].key,
        optionKey: templateIndividual.questions[0].options[1].key,
        instanceKey: "voting-0",
        from: "c"
    },
    {
        key: "vi3",
        questionKey: templateIndividual.questions[1].key,
        optionKey: templateIndividual.questions[1].options[0].key,
        instanceKey: "voting-0",
        from: "b"
    }, {
        key: "vi4",
        questionKey: templateIndividual.questions[1].key,
        optionKey: templateIndividual.questions[1].options[1].key,
        instanceKey: "voting-0",
        from: "c"
    }
]

const votesCollectionConnected = [
    {
        key: "vc0",
        questionKey: templateConnected.questions[0].subQuestions[0].key,
        optionKey: templateConnected.questions[0].options[0].key,
        instanceKey: "voting-1",
        from: "b"
    },
    {
        key: "vc1",
        questionKey: templateConnected.questions[0].subQuestions[0].key,
        optionKey: templateConnected.questions[0].options[0].key,
        instanceKey: "voting-1",
        from: "a"
    }, {
        key: "vc2",
        questionKey: templateConnected.questions[0].subQuestions[0].key,
        optionKey: templateConnected.questions[0].options[1].key,
        instanceKey: "voting-1",
        from: "c"
    },
    {
        key: "vc3",
        questionKey: templateConnected.questions[0].subQuestions[1].key,
        optionKey: templateConnected.questions[0].options[0].key,
        instanceKey: "voting-1",
        from: "b"
    }, {
        key: "vc4",
        questionKey: templateConnected.questions[0].subQuestions[1].key,
        optionKey: templateConnected.questions[0].options[1].key,
        instanceKey: "voting-1",
        from: "c"
    },
    {
        key: "vc5",
        questionKey: templateConnected.questions[0].subQuestions[2].key,
        optionKey: templateConnected.questions[0].options[1].key,
        instanceKey: "voting-1",
        from: "c"
    },
    {
        key: "vc6",
        questionKey: templateConnected.questions[0].subQuestions[3].key,
        optionKey: templateConnected.questions[0].options[1].key,
        instanceKey: "voting-1",
        from: "c"
    }
]

const globals = {
    name: 'Is Godzilla Good?',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

export function VideoVotingQuestionOverviewFeatureDemo() {

    return (
        <Narrow>
            <DemoSection label=" Video Voting results for individual question voting">
                <Datastore
                    serverCall={serverCall}
                    collections={{
                        backlink_article: backlinkCollection,
                        derived_votes: [...votesCollectionConnected, ...votesCollectionIndividual]
                    }
                    }
                >
                    <VotingResults votingDataEntry={templateIndividual} votingInstanceKey={"voting-0"} />
                </Datastore>
            </DemoSection>
            <DemoSection label=" Video Voting results for connected question voting">
                <Datastore
                    serverCall={serverCall}
                    collections={{
                        backlink_article: backlinkCollection,
                        derived_votes: [...votesCollectionConnected, ...votesCollectionIndividual]
                    }}
                >
                    <VotingResults votingDataEntry={templateConnected} votingInstanceKey={"voting-1"} />
                </Datastore>
            </DemoSection>
        </Narrow>
    );
}


export function VideoVotingQuestionOverviewFeatureStorySets() {
    return [
        {
            label: 'Video Voting Result Overview',
            instanceKey: 'a', personaKey: 'a',
            content: <VideoVotingOverview></VideoVotingOverview>,
            serverCall: serverCall,
            collections: {
                backlink_article: backlinkCollection,
                derived_votes: [...votesCollectionConnected, ...votesCollectionIndividual]
            },
            stories: [{
                label: "Open accordions of individual questions",
                actions: [
                    CLICK(templateIndividual.questions[0].text),
                    CLICK(templateIndividual.questions[1].text),
                    CLICK(templateIndividual.questions[2].text),
                    CLICK(templateIndividual.questions[3].text),
                ]
            },
            {
                label: "Open accordions of connected question",
                actions: [
                    CLICK(templateConnected.questions[0].text),
                ]
            }
            ]
        }
    ]
}

export function VideoVotingQuestionOverviewFullStructureDemo() {
    return <StructureDemo
        structureKey={"question"}
        serverCall={serverCall}
        collections={{
            backlink_article: backlinkCollection,
            derived_votes: [...votesCollectionConnected, ...votesCollectionIndividual]
        }}
        globals={globals}
        features={{"votingoverview": true}}> 
    </StructureDemo>
}