import { UserMultiple } from "@carbon/icons-react";
import { HorizBox, Pad, PadBox, spacings } from "np-platform-client/component/basics";
import { colorWhite } from "np-platform-client/component/color";
import { UtilityText } from "np-platform-client/component/text";
import { useCollection } from "np-platform-client/util/datastore";

export const TopicParticipantsFeature = {
    key: 'topicparticipants',
    name: 'Topic Participants',
    config: {
        topicHeaderBottomWidgets: [TopicParticipantsWidget]
    }
}

function TopicParticipantsWidget() {
    const personas = useCollection('persona');
    const count = personas?.length;
    
    return <PadBox top={24}>
        <HorizBox>
            <UserMultiple color={colorWhite} />
            <Pad size={spacings.xs} />
            <UtilityText color={colorWhite} label='{count} {noun}' 
                formatParams={{count, singular: 'participant', plural: 'participants'}} 
            />
        </HorizBox>
    </PadBox>
}
