import { Banner } from "np-platform-client/component/banner";
import { Narrow, Pad, PadBox } from "np-platform-client/component/basics";
import { DemoHeader, DemoSection, SpacedArray } from "np-platform-client/component/demo";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { VideoPlayerStateContextProvider } from "../../contrib/zdf/videoPlayerStateContext";
import { View } from "react-native";
import { SimpleVideo } from "../../contrib/zdf/videoPlayer";
import { OverlayScreen } from "../../contrib/zdf/overlay";
import { colorGreyPopupBackground } from "np-platform-client/component/color";
import { RichText } from "np-platform-client/component/richtext";

export function PositionableVideoOverlayDemo() {
    return (
        <Narrow>
            <DemoHeader strong label="Positionable Video Overlay Demo" />
            <Pad/>
            <DemoSection label="VideoPlayerStateContextProvider & OverlayScreen">
                <VideoPlayerStateContextProvider>
                    <View>
                        <SimpleVideo
                            src={
                                "https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
                            }
                            controls
                        />

                        <OverlayScreen>
                            <View style={{ backgroundColor: "white" }}>
                                <UtilityText label={"Default position"} />
                            </View>
                        </OverlayScreen>

                        <OverlayScreen position={{ left: "50px", top: "50px" }}>
                            <View style={{ backgroundColor: "white" }}>
                                <UtilityText label={"Position: left: 50px top: 50px"} />
                                <UtilityText label={"Dimension adapts to content"} />
                            </View>
                        </OverlayScreen>

                        <OverlayScreen position={{ left: "50px", top: "100px" }} dimension={{ height: "100px" }}>
                            <View style={{ height: "100%", backgroundColor: "white" }}>
                                <UtilityText label={"Position: left: 50px top: 100px"} />
                                <UtilityText label={"Dimension: height: 100px"} />
                                <UtilityText label={"Dimension is fixed"} />
                            </View>
                        </OverlayScreen>

                        <OverlayScreen position={{ left: "300px", top: "50px" }} dimension={{ width: "75px" }}>
                            <View style={{ backgroundColor: "white" }}>
                                <UtilityText label={"Position: left: 300px top: 50px"} />
                                <UtilityText label={"Dimension: width: 75px"} />
                            </View>
                        </OverlayScreen>

                        <OverlayScreen
                            position={{ right: "0%", top: "10%" }}
                            dimension={{ width: "20%", height: "60%" }}
                        >
                            <View style={{ height: "100%", backgroundColor: "white" }}>
                                <UtilityText label={"Position: right: 0% top: 10%"} />
                                <UtilityText label={"Dimension: width: 20% height: 60%"} />
                            </View>
                        </OverlayScreen>
                    </View>
                </VideoPlayerStateContextProvider>
            </DemoSection>
            <Banner color={colorGreyPopupBackground}>
                <SpacedArray pad={10}>
                    <Heading label="How to use OverlayScreen" />
                    <UtilityText label={"position - Specify the position of the overlay"} />
                    <UtilityText
                        label={
                            "dimension - Specify height or width to set a fixed size. If no dimension is specified, the overlay will adapt its size to its content. "
                        }
                    />
                    <RichText label="repositionIframe - **Caution:** repositionIframe will cause the whole PSI iframe to act as the inner container" />
                </SpacedArray>
            </Banner>
        </Narrow>
    );
}
