import { DemoSection, SpacedArray } from "np-platform-client/component/demo"
import { UtilityText } from "np-platform-client/component/text"
import { View } from "react-native-web"
import { AdaptiveLayoutQuestionTeaserWithComment, CardStack, CommentPreview, EditorialQuestionFeature, EditorialQuestionTeaser, MiniQuestionPromo, MultiQuestionTeaser, NarrowQuestionTeaserWithComment, QuestionInfo, SingleQuestionTeaser, TeaserButton, WideQuestionTeaserWithCommnt } from "../feature/teaser/EditorialQuestionFeature"
import { Datastore } from "np-platform-client/util/datastore"
import { PadBox } from "np-platform-client/component/basics"
import { Edit } from "@carbon/icons-react"

export const TeaserDemoFeature = {
    key: 'demo_teaser',
    name: 'Demo - Teasers',
    config: {
        componentSections: [
            {label: 'Teaser', pages: [
                {label: 'Old Teaser', key: 'oldteaser', screen: OldTeaserScreen},
                {
                    label: 'New Teaser', 
                    key: 'newteaser', 
                    designUrl: 'https://www.figma.com/design/89oxuIEpP7z2xKcYEnnLzF/Teaser-%2B-Login-Explorations?node-id=0-1&t=BvB2H3E37TVfrDKm-0',
                    screen: NewTeaserScreen
                },
            ]}
        ]
    }
}


function OldTeaserScreen() {
    const question = {
        key: 1,
        from: 'a',
        text: 'Is Godzilla good?',
    }
    const questionTwo = {
        key: 2,
        from: 'b',
        text: 'Should we welcome Godzilla to Tokyo?',
    }
    const collections = {
        derived_questionPreview: [
            {key: 1, commentCount: 1234}
        ]
    }
    const config = EditorialQuestionFeature.defaultConfig;

    return <View>
        <DemoSection label='Mini Question Promo'>
            <Datastore collections={collections}>
                <SpacedArray>
                    <MiniQuestionPromo question={question} />
                    <MiniQuestionPromo question={questionTwo} joinButton/>
                </SpacedArray>
            </Datastore>
        </DemoSection>
        <DemoSection label='Single Question Teaser'>
            <Datastore collections={collections}>
                <SingleQuestionTeaser question={question} />
            </Datastore>
            <Datastore collections={collections} roles={['Editoral']}>
                <SingleQuestionTeaser question={questionTwo} />
            </Datastore>
        </DemoSection>
        <DemoSection label='Multi Question Teaser'>
            <Datastore collections={collections}>           
                <MultiQuestionTeaser questions={[question, questionTwo]} />
            </Datastore>
        </DemoSection>
        <DemoSection label='Editorial questions Teaser'>
            <Datastore collections={{...collections, question: [question, questionTwo]}} config={config}>
                <EditorialQuestionTeaser />
            </Datastore>
            <Datastore roles={['Editorial']} collections={{...collections, question: [question, questionTwo]}} config={config}>
                <EditorialQuestionTeaser />
            </Datastore>
            <Datastore roles={['Editorial']} >
                <EditorialQuestionTeaser />
            </Datastore>
        </DemoSection>
    </View>
}



function NewTeaserScreen() {
    const question = {
        key: 1,
        from: 'a',
        text: 'Is Godzilla good?',
    }

    const commentPreview = {
        key: 1,
        from: 'a',
        text: 'I love Godzilla. He is so cute.',
        time: Date.now(),
        upvoteCount: 5
    }

    const collections = {
        derived_questionPreview: [
            {key: 1, commentCount: 1234, commentPreview}
        ]
    }

    const configWithGraphic = {...EditorialQuestionFeature.defaultConfig, showZeroStateGraphic: true};

    // return null;
    return <View>
        <Datastore collections={collections}>
            <DemoSection label='Adaptive Layout Question Teaser With Comment'>
                <AdaptiveLayoutQuestionTeaserWithComment question={question} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>
            <DemoSection label='Wide Question Teaser With Comment'>
                <WideQuestionTeaserWithCommnt question={question} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>
            <DemoSection label='Narrow Question Teaser With Comment'>
                <NarrowQuestionTeaserWithComment question={question} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>
        </Datastore>
        <Datastore>
            <DemoSection label='Adaptive Empty State'>
                <AdaptiveLayoutQuestionTeaserWithComment question={question} />
            </DemoSection>
            <DemoSection label='Wide Empty State'>
                <WideQuestionTeaserWithCommnt question={question} />
            </DemoSection>
            <DemoSection label='Narrow Empty State'>
                <NarrowQuestionTeaserWithComment question={question} />
            </DemoSection>
        </Datastore>
        <Datastore config={configWithGraphic}>
            <DemoSection label='Adaptive Empty State With Graphic'>
                <AdaptiveLayoutQuestionTeaserWithComment question={question} />
            </DemoSection>
        </Datastore>
        


        <DemoSection label='Comment Stack'>
            <CardStack>
                <PadBox horiz={20} vert={20}>
                    <UtilityText text='Content goes here' />
                </PadBox>
            </CardStack>
        </DemoSection>

        <DemoSection label='Teaser Button'>
            <TeaserButton label='Teaser Button' icon={<Edit color='white' />} />
        </DemoSection>

        <Datastore>
            <DemoSection label='Comment Preview'>
                <CommentPreview commentPreview={commentPreview} />
            </DemoSection>
        </Datastore>

        <DemoSection label='Question Info'>
            <QuestionInfo question={question} commentCount={42} />
        </DemoSection>

    </View>
}
