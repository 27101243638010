import { StyleSheet, View } from "react-native";

import { Datastore } from "np-platform-client/util/datastore";
import { STATIC_TOPIC } from '../../structure/cbcrc/topic/static';
import { ConversationScreen, Pad, spacings } from "np-platform-client/component/basics";
import { EditorialHeading, Heading } from "np-platform-client/component/text";
import { TitledWriter } from "../../feature/cbcrc/profile/titledWriter";
import { RecirculationSlider } from "../../component/cbcrc/recirculationCards";
import { PollScreen, PollWidgetScreen } from './polldemo';
import { TopicStructureFullDemo } from "./topicstructuredemo";
import { TopicTeaserScreen } from "./topicteaserdemo";


export const CBCRCDemoFeature = {
    key: 'demo_cbcrc',
    name: 'Demo CBCRC',
    config: {
        componentSections: [
            {label: 'CBC-RC Components', key: 'cbcrc', pages: [
                {label: 'Poll', key: 'poll', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6311-22005&t=2tD3gEuK5FbZOslE-0', screen: PollScreen},
                { label: 'PollWidget', key: 'pollWidget', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6311-22005&t=2tD3gEuK5FbZOslE-0', screen: PollWidgetScreen},
                { label: 'Titled Writer', key: 'titledWriter', designUrl: '', screen: TitledWriterScreen},
                { label: 'Recirculation Slider / Cards', key: 'recirculation', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6312-22717&t=sVDGhoOkO4He5usT-0', screen: RecirculationScreen,},
                { label: 'Topic Teaser', key: 'topicTeaser', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6376-16667&node-type=FRAME&t=Qi3NQID2OPtk4FfL-0', screen: TopicTeaserScreen }
            ]}
        ],
        structureSections: [
            {label: 'CBC-RC Structures', key: 'cbcstruct', pages: [
                {label: 'Topic of the moment', key: 'topicofthemoment', screen: TopicStructureFullDemo},
            ]},
        ],
    }
}

function TitledWriterScreen() {

    return <ConversationScreen>
         <Pad size={spacings.md} />
         <EditorialHeading level={3} strong text='Titled Writer' />
        <Pad size={spacings.md} />
          <View style={DemoStyle.titledWiterBox}>
            <TitledWriter  userId={null}/>
         </View>
         <Pad size={spacings.md} />
         <EditorialHeading level={3} strong text='Titled Writer anonymous' />
         <Pad size={spacings.md} />
         <View style={DemoStyle.titledWiterBox}>
            <TitledWriter userId={null} anonymous={true} />
         </View>
    </ConversationScreen>
}

function RecirculationScreen() {
    const relatedConversations = [
        {
            key: 1,
            id: 1,
            from: 'a',
            text: 'Is Godzilla good?',
            picture: 'https://placehold.co/250x150',
        },
        {
            key: 2,
            id: 2,
            from: 'b',
            text: 'Is Kong bad?',
            picture: 'https://placehold.co/250x150',
        },
        {
            key: 3,
            id: 3,
            from: 'c',
            text: 'Would you watch Gozilla vs Kong?',
            picture: 'https://placehold.co/250x150',
        },
    ]

    const collections = {
        derived_relatedQuestionPreview: [
            {key: 1, commentCount: 1234, userList: ['a','b','c']},
            {key: 2, commentCount: 64, userList: ['a','b','c']},
        ]
    };

    const {relatedContents, testContents, relatedTopics} = STATIC_TOPIC;

    return <ConversationScreen>
        <Datastore collections={collections}>
            <Heading level={1} label='Thread Promo - large' />
            <Pad size={spacings.xl} />
            <RecirculationSlider cardsData={relatedConversations} cardType='conversation' controls cardSize="large" />
            <Pad size={spacings.xl2 * 2} />
            <Heading level={1} label='Thread Promo - small' />
            <Pad size={spacings.xl} />
            <RecirculationSlider cardsData={relatedConversations} cardType='conversation' controls />
            <Heading level={1} label='Content Promo' />
            <Pad size={spacings.xl2 * 2} />
            <RecirculationSlider cardsData={relatedContents} cardType='content' controls />
            <Pad size={spacings.xl2 * 2} />
            <Heading level={1} label='Single slide demo' />
            <Pad size={spacings.xl} />
            <RecirculationSlider cardsData={testContents} cardType='content' />
            <Pad size={spacings.xl2 * 2} />
            <Heading level={1} label='Previous topics demo' />
            <Pad size={spacings.xl} />
            <RecirculationSlider cardsData={relatedTopics} cardType='topic' />
        </Datastore>
    </ConversationScreen>
}

const DemoStyle = StyleSheet.create({
    demo: {
        paddingHorizontal: 20,
        paddingVertical: 15,
    },
    titledWiterBox: {
        boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.10)',
        padding: 20,
        borderRadius: 12,
    }
})