import { UtilityText } from "np-platform-client/component/text";
import { TopicLiveStatus } from "../../../component/cbcrc/header";
import { useGlobalProperty } from "np-platform-client/util/datastore";
import { colorWhite } from "np-platform-client/component/color";

export const TopicLiveStatusFeature = {
    key: 'topiclivestatus',
    name: 'Live Status',
    config: {
        topicHeaderLeftWidgets: [TopicLiveStatusWidget],
        topicDescriptionWidget: TopicLiveStatusDescription
    }
}

function TopicLiveStatusWidget() {
    const status = useGlobalProperty('status');
    const live = status === 'live';

    return <TopicLiveStatus live={live} />    
}

function TopicLiveStatusDescription() {
    const status = useGlobalProperty('status');
    const label = status === 'live' ? 'Topic of the moment' : 'Discussion topic';

    return <UtilityText color={colorWhite} label={label} />
}


