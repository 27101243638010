
import { colorPink, colorTextGrey } from "np-platform-client/component/color";
import { UtilityText } from "np-platform-client/component/text";
import { gotoInstance } from "np-platform-client/util/navigate";
import { useLanguage } from "np-platform-client/component/translation";
import { formatMiniDate } from "np-platform-client/component/date";
import { StyleSheet, View, Image } from "react-native";
import { IconCircleCheck } from "np-platform-client/component/icon";
import { usePersonaKey, usePersonaObject } from "np-platform-client/util/datastore";
import { Pad, spacings } from "np-platform-client/component/basics"; 
import { TextButton } from "np-platform-client/component/button";

export function TitledWriter({ userId, anonymous = false }) {
    
    const personaObj = usePersonaObject(userId) ||  usePersonaObject(usePersonaKey()) ;

    //TODO remove those hardcoded values when those properties returned from backend
    personaObj.badgeUrl = 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/perso/logo-papillon-radio-canada.jpg'
    personaObj.role = 'journalist'
    personaObj.organization = 'CBC / RC'

    return <Byline
                name={ (anonymous) ? personaObj.organization : personaObj.name }
                time={Date.now()}
                badge={(anonymous) ? null : personaObj.badgeUrl}
                photo={(anonymous) ? personaObj.badgeUrl : personaObj.photoUrl}
                tag = {(anonymous) ? null : personaObj.role} />
}

export function Byline({clickable=true, userId, name=null, photo=null, time, underline=false, edited=false, badge=null, tag=null}) {
    const s = BylineStyle
   
    const language = useLanguage();

    function onProfile() {
        gotoInstance({structureKey: 'profile', instanceKey: userId});
    }

    return <View style={s.outer}>
            <ProfilePhoto userId={userId} photo={photo} type={'large'} badge={badge} />
             <Pad size={spacings.xs} />
             <View style={{ flexDirection: 'column' }}>
                <View style={{ flexDirection: 'row' }}>
                    {clickable ?
                        <TextButton type='small' strong text={name} underline={underline} onPress={onProfile} />
                    : 
                        <UtilityText weight='medium' text={name} underline={underline} />
                    }
                    {time && <Pad size={6} />}
                    {time &&                     <UtilityText color={colorTextGrey} 
                                label={'{time}' + (edited ? ' • Edited' : '')} 
                                formatParams={{time: formatMiniDate(time, language)}} underline={underline}/>
                    }
                </View>
                {tag && <View style={s.tag}>
                     <UtilityText label={tag} weight='medium' type='tiny' />
                </View>
                }
                 
        </View>
    </View>   
}
const BylineStyle = StyleSheet.create({
    outer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    tag: {
        alignSelf: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: 1,
        marginTop: 4,
        paddingHorizontal: spacings.xs,
        paddingVertical: spacings.xxs,
        borderRadius: 100,
        backgroundColor: colorPink,
        borderColor: colorPink,
    }
})

export function ProfilePhoto({userId, type='large', photo=null, faint=false, check=false, border=false, badge=null}) {
    return <FaceImage face={photo} photoUrl={photo} type={type} 
                border={border} faint={faint} check={check} badge={badge} />
}

export function FaceImage({face, photoUrl=null, type='small', faint=false, check=false, border=false, badge=null}) {
    const sizeMap = {
        huge: 80,
        large: 40,
        small: 32,
        tiny: 24,
    }
    const size = sizeMap[type] ?? 32;

    const checkPadMap = {
        large: 2,
        small: 4,
        tiny: 8,
    }
    const checkPad = check ? checkPadMap[type ?? 'small'] : 0;

    return <View style={{position: 'relative', alignSelf: 'flex-start'}}>
            <Image 
            style ={{
                width: size, height: size, borderRadius: size /2, 
                opacity: faint ? 0.5 : 1, marginRight: checkPad,
                borderWidth: border ? 2 : 0,
                borderColor: 'white'
            }}
            source={{uri: photoUrl ?? ('https://new-public-demo.web.app/faces/' + face)}} />
            {badge && <View style={{position: 'absolute', right: 0, bottom: 0}}>
             <Image 
                style={{
                    width: 14, height: 14, borderRadius: 7,
                    borderColor: 'white',
                    backgroundColor: 'white',
                    borderWidth: 2
                }}
                source={{ uri: badge }} />
            </View>}
            {check && <View style={{position: 'absolute', right: 0, bottom: 0}}>
                <IconCircleCheck />
            </View>}
    </View>
}


