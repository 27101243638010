import { ConversationScreen, Narrow } from "np-platform-client/component/basics"
import { DemoSection } from "np-platform-client/component/demo"
import { useState } from "react"
import { AdminPerspectiveEditorButton, AdminPerspectiveEditorModal, CircleCount, ComposerPerspectiveSelector, MissingPerspectivesCard, PerspectiveFilter, PerspectiveFilterPopup, PerspectivesForComment, PerspectiveToggle } from "../feature/question/RepresentingPerspectives"
import { Datastore } from "np-platform-client/util/datastore"
import { Demo } from "@carbon/icons-react"
import { addKey, removeKey } from "np-platform-client/util/util"
import { StructureDemo } from "np-platform-client/util/instance"

export function RepresentingPerspectivesComponentsDemo() {
    const [comment, setComment] = useState({perspectives: {parent: {emoji: '👩‍👧‍👦', text: 'Parent'}, child: {emoji: '👧', text: 'Child'}}})
    const globals = {perspectives: '👩‍👧‍👦 Parent\n👧 Child\n👨‍🏫 Teacher\n👩‍💼 School Administrator\nEmojiless Person'}

    function onClose() {
        console.log('onClose');
    }
    const collections = {comment: [
        {from: 'a', text: 'a comment', perspectives: {Parent: {emoji: '👩‍👧‍👦', text: 'Parent'}, Child: {emoji: '👧', text: 'Child'}}}
    ]}
    const [editedSelected, setEditedSelected] = useState({Parent: true});
    function togglePerspective(text) {
        setEditedSelected(editedSelected?.[text] ? removeKey(editedSelected, text) : addKey(editedSelected, text));
    }

    return <ConversationScreen>
        <Narrow>
            <DemoSection label='Admin View - Missing Perspectives Card'>
                <Datastore globals={globals} collections={collections} roles={['Editorial']}>
                    <MissingPerspectivesCard />
                </Datastore>
            </DemoSection>   
            <DemoSection label='Perspectives For Comment' >
                <PerspectivesForComment comment={comment} />
                <PerspectivesForComment comment={{text: 'hello'}} />
            </DemoSection>
            <DemoSection label='Missing Perspectives Card'>
                <Datastore globals={globals} collections={collections}>
                    <MissingPerspectivesCard />
                </Datastore>
                <Datastore globals={globals} collections={collections} personaKey='b'>
                    <MissingPerspectivesCard />
                </Datastore>
                <Datastore globals={globals} >
                    <MissingPerspectivesCard />
                </Datastore>
                <Datastore globals={{perspectives: '👩‍👧‍👦 Parent\n👧 Child'}} collections={collections}>
                    <MissingPerspectivesCard />
                </Datastore>
            </DemoSection>         
            <DemoSection label='Composer Perspective Selector'>
                <Datastore globals={globals}>
                    <ComposerPerspectiveSelector comment={comment} setComment={setComment} />
                </Datastore>
            </DemoSection>
            <DemoSection label='Perspective Toggle'>
                <PerspectiveToggle comment={comment} setComment={setComment} perspective={{emoji: '👩‍👧‍👦', text: 'Parent'}} />
            </DemoSection>
            <DemoSection label='Perspective Filter'>
                <Datastore globals={globals} collections={collections}>
                    <PerspectiveFilter />
                </Datastore>
            </DemoSection>
            <DemoSection label='Perspective Filter Popup'>
                <Datastore globals={globals} collections={collections}>
                <PerspectiveFilterPopup editedSelected={editedSelected} togglePerspective={togglePerspective}
                    perspectiveList={[{emoji: '👩‍👧‍👦', text: 'Parent'}, {emoji: '👧', text: 'Child'}]} onApply={onClose} />
                </Datastore>
            </DemoSection>
            <DemoSection label='Circle Count'>
                <CircleCount count={4} />
            </DemoSection>
        </Narrow>
    </ConversationScreen>
}

const comments = [
    {key: 1, from: 'a', text: 'Lead it away with food', perspectives: {"Animal Rights Activist": {"emoji":"🐾","text":"Animal Rights Activist"}}},
    {key: 2, from: 'b', text: 'Blow it up', perspectives: {"Military Leader": {"emoji":"🪖","text":"Military Leader"}}},
    {key: 3, from: 'c', text: "I don't know, but it just destroyed my house", perspectives: {"New York Resident": {"emoji":"🗽","text":"New York Resident"}}},
]

const globals = {
    name: 'What should we do about the Giant Gorilla rampaging New York City?',
    perspectives: '🗽 New York Resident\n🐾 Animal Rights Activist\n🏙️ City Planner\n🪖 Military Leader',
    questionAuthor: 'a',
    questionTime: Date.now(),
}


export function RepresentingPerspectivesFullDemo() {
    const collections = {comment: comments}
    return <StructureDemo roles={['Editoral']}
        globals={globals} collections={collections} 
        structureKey='question' 
        features={{representing_perspectives: true, representing_perspectives_card: true}} 
    />
}