import { StructureDemo } from "np-platform-client/util/instance";

const comments = [
    {key: 1, from: 'a', slider: 1, text: 'Who is Godzilla?'},
    {key: 2, from: 'b', slider: 5, text: 'Why are Godzilla and Kong fightning?'},
    {key: 3, from: 'c', slider: 3, text: 'Why is Mothra the best?'},
    {key: 4, from: 'd', slider: 3, text: 'Why is Mechagodzilla the best?'},
    {key: 5, from: 'e', slider: 3, text: 'Why is King Gidorah the best?'},
    {key: 6, from: 'f', slider: 3, text: 'Why is Rodan the worst?'},
]

const topicPoll = [
    {key: 1, from: 'a', slider: 1, value: { label: 'Very negative'}},
    {key: 2, from: 'b', value: { label: 'Neutral'}},
    {key: 3, from: 'c', value: { label: 'Very positive'}},
]


const upcomingTopicPoll = [
    {key: 1, from: 'a', slider: 1, value: { label: 'War in Ukraine'}},
    {key: 2, from: 'b', value: { label: 'Other', comment: 'Godzilla'}},
    {key: 3, from: 'c', value: { label: 'Violence in Toronto'}},
]

const relatedConversations = [
    { key: 'why-is-x?' },
    { key: 'why-is-y?' },
    { key: 'why-is-z?' },
]

const globals = {
    name: 'How artificial intelligence impacts your life?',
    status: 'live', // infer from start/end at?
    startAt: '2024-06-01',
    endAt: '2025-06-30',
    conversationStarter: 'Submit your question about AI to our panel of experts',
    poll: {
        question: 'How do you feel about the increasing use of AI in everyday life?',
        resultline: 'Your response',
        tagline: 'Select one option',
        type: 'MultipleChoice',
        showDataviz: true,
        options: [ {label: 'Very positive'},{label: 'Neutral'},{label: 'somewhat negative'}, {label: 'Very negative'}],
    },
    relatedContents: [
        {
            text: 'Le recours à l’IA en hausse dans la population canadienne',
            picture: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-premiere/16x9/mdr-robot-conversationnel.jpg',
            type: 'text',
            url: 'https://ici.radio-canada.ca/nouvelle/2048276/ia-canada-sondage-hausse-utilisation',
            id: 1,
        },
        {
            text: 'Google et la gestion de la circulation : la Ville espère des économies',
            picture: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/16x9/gestionnaire-arteriel.jpg',
            type: 'text',
            url: 'https://ici.radio-canada.ca/nouvelle/2046551/intelligence-artificielle-circulation-automobile-gestion-arterielle',
            id: 2,
        },
        {
            text: 'Des artistes poursuivent OpenAI, accusée d’avoir utilisé leurs livres pour ChatGPT',
            picture: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/16x9/ai-intelligence-artificielle-chatgpt.jpg',
            type: 'text',
            url: 'https://ici.radio-canada.ca/nouvelle/1995206/livres-chatgpt-openai-poursuite',
            id: 3,
        },
        {
            text: 'Prédire les complications du cancer grâces à L’IA',
            picture: 'https://images.radio-canada.ca/v1/ici-tele/16x9/decouverte-sante-ia-intelligence-artificielle-4-fevrier-2024.jpg',
            type: 'media',
            url: 'https://ici.radio-canada.ca/info/videos/1-8887402/predire-complications-cancer-graces-a-ia',
            id: 4,
        },
    ],
    testContents: [
        {
            id: '3ldrP0ctarks-test',
            text: "Prédire les complications du cancer grâce à l'IA",
            picture: 'https://placehold.co/250x150',
            type: 'media',
        },
    ],
    nextTopicPoll: {
        question: 'Which topic would you like us to cover next?',
        tagline: 'Vote on one topic',
        resultLine: 'Your vote',
        type: 'MultipleChoiceOther',
        showDataviz: false,
        otherOptionValue: 'Other',
        otherOptionPlaceholder: 'Write in a topic...',
        options: [{ label:  'War in Ukraine' },
                { label: 'Violence in Toronto' },
                { label: 'Immigration protest' },
                { label: 'First nation leadership'},
            ],
    },
}

const allTopicFeatures = {
    topicuserquestions: true,
    topicrelatedcontent: true,
    topicpoll: true,
    nexttopicpoll: true,
    topicuserquestions: true,
    topicfollow: true,
    topiclivestatus: true,
    topicparticipants: true,
}

export function TopicStructureFullDemo() {
    const collections = {
        comment: comments,
        topicPoll,
        upcomingTopicPoll,
        derived_relatedQuestionPreview: relatedConversations,
    }
    return <StructureDemo roles={['Editorial']} features={allTopicFeatures}
        globals={globals} collections={collections} 
        structureKey='topic'
    />
}