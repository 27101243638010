import { Center, Pad, PadBox, spacings } from "np-platform-client/component/basics";
import { REPLACE_CBC_CommentsWithSort, REPLACE_CBC_PopularComments } from "../../../component/cbcrc/comments";
import { ActionEdit, ActionReply, ActionReport, CommentsInput, ComposerScreen } from "np-platform-client/component/comment";
import { Heading, Paragraph } from "np-platform-client/component/text";
import { Catcher } from "np-platform-client/system/catcher";
import { useCollection, useDatastore, useGlobalProperty } from "np-platform-client/util/datastore";
import { CTAButton } from "np-platform-client/component/button";
import { colorTextGrey } from "np-platform-client/component/color";

export const TopicUserQuestionsFeature = {
    key: 'topicuserquestions',
    name: 'Community Questions',
    config: {
        topicContentWidgets: [TopicQuestions]
    },
    subscreens: {
        allQuestions: () => <AllQuestions />,
        composer: ({ commentKey, about }) => <ComposerScreen about={about} commentKey={commentKey} intro={<QuestionHeader />} contentType='Public Response' />,
    },
    defaultConfig: {
        commentActions: [ActionReply],
        commentRightActions: [ActionReport, ActionEdit],
        commentTopWidgets: [],
        commentAboveWidgets: [],
        replyAboveWidgets: [],
        commentAllowEmpty: false,
        commentEditBottomWidgets: [],
        commentEditTopWidgets: [],
        commentPostBlockers: [],
        commentInputPlaceholder: 'Ask us a question...',
        commentReplyPlaceholder: 'Reply to {authorName}...',
        commentInputLoginAction: 'answer',
        commentPostCheckers: [],
        commentFilters: [],
        commentRankers: [],
        replyFilters: [],
        pageTopWidgets: [],
        topBanners: [],
        composerTopBanners: [],
        composerSubtitle: () => 'Public response',
        poll: null,
        noCommentsMessage: 'Be the first to share your thoughts.',
        noMoreCommentsMessage: 'No more questions',
        PollWidget: null,
        visualizationType: 'percent',
        teaser: null,
        adminScreen: null,
    },
}

function TopicQuestions() {
    const datastore = useDatastore();
    const comments = useCollection('comment', {filter: {about: null, replyTo: null}, sortBy: 'time'});
    const conversationStarter = useGlobalProperty('conversationStarter');

    return <PadBox top={spacings.xl2 * 2} horiz={spacings.lg}>
        <Heading level={1} label={conversationStarter} />
        <Pad size={spacings.xl} />
        <CommentsInput />
        <Pad size={spacings.sm} />
        <Paragraph color={colorTextGrey} label='Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.' />
        <Pad size={spacings.xl2} />
        <Heading label='Popular questions' level={2} />
        <Catcher>
            <REPLACE_CBC_PopularComments canPost={false} />
        </Catcher>
        {comments?.length > 4 && (
            <>
                <Pad size={spacings.lg} />
                <Center>
                    <CTAButton type='primary' label='View all questions' 
                        onPress={datastore.needsLogin(() => datastore.pushSubscreen('allQuestions', {}))} />
                </Center>
            </>
        )}
    </PadBox>
}


function QuestionHeader() {
    const conversationStarter = useGlobalProperty('conversationStarter');

    return <PadBox horiz={spacings.lg} vert={spacings.xl2}>
        <Heading level={1} label={conversationStarter} />
    </PadBox>
}

function AllQuestions() {
    return <PadBox horiz={spacings.lg} vert={spacings.xl2}>
        <Catcher>
            <REPLACE_CBC_CommentsWithSort canPost={false} headingLabel="All questions" />
        </Catcher>
    </PadBox>
}
