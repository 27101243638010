import { View } from "react-native";
import { SubtleButton } from "np-platform-client/component/button";
import { colorTeaserBackground, colorBlackHover } from "np-platform-client/component/color";
import { Pin } from "@carbon/icons-react";
import { useState } from "react";
import { Banner } from "np-platform-client/component/banner";
import { HorizBox, Pad, PadBox, spacings } from "np-platform-client/component/basics"
import { Modal } from "np-platform-client/component/modal";
import { Heading, Paragraph, UtilityText } from "np-platform-client/component/text"
import React from "react";

export const CommunityGuidelinesFeature = {
    name: 'Community Guidelines',
    key: 'community_guidelines',
    config: {
        headerTopLeftWidgets: [CommunityGuidelines]
    }
}

export const GUIDELINES = {
    NO_UNCIVIL: 'No uncivil, hateful, or illegal content',
    NO_HARM: 'No comments that call for or celebrate harm',
    NO_INSULTS: 'No insults, mockery, threats, or personal attacks',
    NO_LABELS: 'Refrain from hostile comments that attach negative labels to, or assume bad intent of specific groups of people',
    BACKUP_CLAIMS: 'Back up contentious claims with sources',
    NO_SPAM: 'No spam or commercial content',
    RESPECT_PRIVACY: 'Respect the privacy of others and do not include personal information in your contributions'
}

export function CommunityGuidelines() {
    const [showCommunityGuidelines, setShowCommunityGuidelines] = useState(false);
    return <View>
        <SubtleButton type='tiny' icon={Pin} color={colorBlackHover} label='Community Guidelines' onPress={() => setShowCommunityGuidelines(true)}/>
        {showCommunityGuidelines && React.createElement(GuidelinesModal, {onClose: () => setShowCommunityGuidelines(false)})}
    </View>
}

export function GuidelinesModal({onClose}) {
    return <Modal onClose={onClose} >
        <PadBox horiz={spacings.lg} vert={spacings.lg}>
            <Heading level={1} label='Community Guidelines'/>
            <Pad />
            <Paragraph label='We strive for a kind and constructive discussion space where everyone feels welcome to contribute their perspectives and lived experiences to the conversation. This discussion space values responses that are on-topic and relevant to the subject matter, including meaningful debate.' />
            <Pad size={21} />
            <Paragraph label='To foster a welcoming and respectful environment, this discussion space uses AI-aided moderation to enforce the following community guidelines:' />
            <Pad size={32} />
            <Banner color={colorTeaserBackground}>
                <View style={{flexDirection: 'row'}}>
                    <UtilityText text='📌' type='large'/>
                    <Pad size={4} />
                    <Heading level={2} label='Community Guidelines'/>  
                </View>
                <Pad size={16}/>
                <BulletText label={GUIDELINES.NO_UNCIVIL} />
                <Pad size={12}/>
                <BulletText label={GUIDELINES.NO_HARM} />
                <Pad size={12}/>
                <BulletText label={GUIDELINES.NO_INSULTS} />
                <Pad size={12}/>
                <BulletText label={GUIDELINES.NO_LABELS} />
                <Pad size={12}/>
                <BulletText label={GUIDELINES.BACKUP_CLAIMS} />
                <Pad size={12}/>
                <BulletText label={GUIDELINES.RESPECT_PRIVACY} />
                <Pad size={12}/>
                <BulletText label={GUIDELINES.NO_SPAM} />            
            </Banner>
        </PadBox>
    </Modal>    
}

function BulletText({label, text}) {
    return <HorizBox>
        <UtilityText text='•' />
        <Pad size={10}/>
        <UtilityText label={label} text={text} />
    </HorizBox>
}