import { useState } from "react";
import { MockTopicFollowButton } from "../../../component/cbcrc/header";

export const MockTopicFollowFeature = {
    key: 'topicfollow',
    name: 'Follow Topic',
    config: {
        topicHeaderRightWidgets: [TopicFollowWidget]
    }
}

function TopicFollowWidget() {
    const [following, setFollowing] = useState(false);

    return <MockTopicFollowButton notificationSubscribed={following} 
        onPress={() => setFollowing(!following)}
    />
}
