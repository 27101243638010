import { Banner } from "np-platform-client/component/banner";
import { HorizBox, Pad, PadBox, spacings } from "np-platform-client/component/basics"
import { CTAButton } from "np-platform-client/component/button";
import { colorPurpleBackground, colorTeaserBackground } from "np-platform-client/component/color";
import { Modal } from "np-platform-client/component/modal";
import { Heading, UtilityText } from "np-platform-client/component/text"
import { View } from "react-native";

export const HumanReviewsEverythingFeature = {
    key: 'human_premoderation',
    name: 'Human Reviews Everything',
    config: {
        headerBelowInputWidgets: [ModerationNote],
        commentEditBottomWidgets: [ModerationNote],
        commentPostTriggers: [sendPostToModeration],
        commentPostCheckers: [showModalAfterPosting]
    }
}

export function ModerationNote() {
    return <PadBox top={20}><UtilityText label='All posts will be manually moderated for civility' /></PadBox>
}

export async function sendPostToModeration({datastore, comment, commentKey}) {
    await datastore.callServerAsync('moderation', 'sendToReview', {
        type: 'comment', setInReview: true, source: 'human_premoderation',
        text: comment.text, key: commentKey, from: comment.from,
    });
}

function BulletText({label, text}) {
    return <HorizBox>
        <UtilityText text='•' />
        <Pad size={10}/>
        <UtilityText label={label} text={text} />
    </HorizBox>
}

export function showModalAfterPosting() {
    return {allow: true, modal: HumanModerationModal}
}

export function HumanModerationModal({onClose}) {
    const buttonRow = <CTAButton wide type='secondary' label='I understand' onPress={onClose}/>

    return <Modal onClose={onClose} buttonRow={buttonRow}>
        <PadBox horiz={spacings.lg} vert={spacings.lg}>
            <Heading level={1} label='Thank you!'/>
            <Pad />
            <UtilityText label='Your response has been sent to a moderator for review.' />
            <Pad size={32} />
            <Banner color={colorTeaserBackground}>
                <Heading level={2} label='What happens next'/>
                <Pad size={16}/>
                <BulletText label='Your response will be reviewed by a moderator' />
                <Pad size={12}/>
                <BulletText label='You should get a response within 24 hours.' />
                <Pad size={12}/>
                <BulletText label='If your response is approved, it will be posted.' />
                <Pad size={12}/>
                <BulletText label='If your response is not approved, you will be notified and given the opportunity to edit and resubmit.' />
            </Banner>
        </PadBox>
    </Modal>    
}

