import { HelpBubble } from "np-platform-client/component/help";
import { useGlobalParams } from "np-platform-client/util/params";

export const SingleResponseHelpFeature = {
    name: 'Single Response Help',
    key: 'single_response_help',
    config: {
        myAnswerBottomWidgets: [HelpIfDifferentUrl],
    }
}

function HelpIfDifferentUrl({comment}) {
    const {entryUrl} = useGlobalParams();
    if (!comment.entryPoints) return null;
    const entryPointForHere = comment?.entryPoints?.find(entry => entry.url === entryUrl);
    if (entryPointForHere) return null;

    return <HelpBubble 
        padTop={12}
        titleLabel="Same question, new article?" 
        label='Update your previous response with fresh insights' 
        helpKey='single-response' condition={true} left pointer
    />
}
