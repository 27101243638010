import { StyleSheet, View, Image } from "react-native";
import { useEffect, useState } from "react";
import { Pad, PadBox, HoverView } from "np-platform-client/component/basics";
import { Heading, UtilityText, LinkText, WebLinkTextButton } from "np-platform-client/component/text";
import { colorGreyPopupBackground, colorDisabledBackground, colorGreyHover, colorIconButtonPress, colorTextGrey } from "np-platform-client/component/color";
import { Close } from '@carbon/icons-react';
import { useGlobalParams, useInstanceParams } from "np-platform-client/util/params"; 

export const ContextEntryPointsFeature = {
    name: 'Context Entry Points',
    key: 'context_entry_points',
    config: {
        composerTopWidgets: [ComposerEntryPointCard],
        commentMiddleWidgets: [PostedCommentEntryPointCard],
    }
}

export function ComposerEntryPointCard({comment, setComment}) {
    const {entryTitle, entryImage, entryUrl} = useGlobalParams();
    const [entryPoints, setEntryPoints] = useState(() => {
        const currentEntry = { title: entryTitle, image: entryImage, url: entryUrl, isClosed: false };
        const existingEntryPoints = comment.entryPoints || [];
        const currentEntryExists = existingEntryPoints.some(entry => entry.title === currentEntry.title);
        return currentEntryExists
            ? existingEntryPoints.map(entry => entry.title === currentEntry.title ? { ...entry, isClosed: false } : entry)
            : [...existingEntryPoints, currentEntry];
    });    

    useEffect(() => {
        setComment({ ...comment, entryPoints });
    }, []);

    const handleClose = (closedEntryTitle) => {
        const updatedEntryPoints = entryPoints.map(entry =>
            entry.title === closedEntryTitle ? { ...entry, isClosed: true } : entry
        );
        setEntryPoints(updatedEntryPoints);
        setComment({ ...comment, entryPoints: updatedEntryPoints });
    };

    if (entryPoints.filter(entry => !entry.isClosed).length === 0) return null;

    return <View>
        {entryPoints.map(entry => (
            <View key={entry.title}>
                {!entry.isClosed && <ContextEntryPointCard entryPoint={entry} handleClose={handleClose} />}
            </View>
        ))}
        <PadBox bottom={20} />
    </View>
}

export function PostedCommentEntryPointCard({comment}) {
    const entryPoints = comment?.entryPoints?.filter(entry => !entry.isClosed) || [];
    if (entryPoints.length === 0) return null;

    return <View>
        <PadBox top={16} />
        {entryPoints.map(entry => (
            <ContextEntryPointCard key={entry.title} entryPoint={entry} />
        ))}
    </View>
}

function ContextEntryPointCard({entryPoint = {}, handleClose}) {
    const {title, image, url} = entryPoint;
    const s = ContextEntryPointCardStyle;
    return <View>
        <PadBox top={handleClose ? 16 : 8} />
        <View style={s.outerBox}>
            <Image source={{ uri: image }} style={s.image} />
            <PadBox right={8} />
            <View style={s.textContainer}>
                <UtilityText type='small' weight="medium" label='Joined from' color={colorTextGrey} />
                <WebLinkTextButton url={url} underline={false} type='small' text={title} numberOfLines={1} ellipsizeMode='tail' />
            </View>
            <PadBox right={8}/>
            {handleClose && <CloseContextButton onPress={() => handleClose(title)} testID={`Close ${title}`} />}
        </View>
    </View>
}
const ContextEntryPointCardStyle = StyleSheet.create({
    outerBox: {
        width: '100%',
        borderRadius: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    image: {
        height: 32,
        width: 32,
        borderRadius: 8,
    },
    textContainer: {
        flex: 1
    }
});

function CloseContextButton({onPress, testID}) {
    const s = CloseContextStyle;
    return <HoverView style={s.regular} hoverStyle={s.hover} 
        pressedStyle={s.pressed} onPress={onPress} testID={testID}>
        <Close/>
    </HoverView>
}

const CloseContextStyle = StyleSheet.create({
    regular: {
        width: 32,
        height: 32,
        borderRadius: 16,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colorDisabledBackground,
    },
    hover: {
        backgroundColor: colorGreyHover
    },
    pressed: {
        backgroundColor: colorIconButtonPress
    }
})