
export const ui_translations_german = {
    'Join the Conversation': 'Unterhaltung zum Thema',
    "Join the conversation": 'Unterhaltung zum Thema',
    "Join the conversation to enter": "Treten Sie der Diskussion bei, um teilzunehmen",
    "Join the conversation to answer": "Treten Sie der Diskussion bei, um zu antworten",
    "Join the discussion": "Beteilige Dich am Gespräch",
    'question': 'Frage',
    'There {isAre} {count} {noun} being discussed': 'Es werden {count} {noun} diskutiert',
    '🚀 Read More': '🚀 Mehr lesen',
    'Related questions': 'Verwandte Fragen',
    'Ask a question related to this topic, or join the conversation by submitting an answer. Be sure to follow our [community guidelines](https://www.google.com).': 'Stelle eine Frage zu diesem Thema oder beteilige dich an der Konversation, indem du eine Antwort einreichst. Stelle sicher, dass du unsere [Gemeinschaftsrichtlinien](https://www.google.com) befolgst.',
    'Ask a question': 'Eine Frage stellen',
    'answer': 'Antwort',
    'answers': 'Antworten',
    'Your answer is posted': 'Deine Antwort wurde veröffentlicht',
    'story': 'Geschichte',
    'Community Guidelines': 'Community-Regeln',
    'Loading...': 'Lädt...',
    'Filter by opinion': 'Nach Meinung filtern',
    'Posting...': 'Wird veröffentlicht...',
    'Loading questions...': 'Fragen werden geladen...',
    'questions': 'Fragen',
    'Read more': 'Mehr lesen',
    'Upvoted ({count})': 'Hochgewählt ({count})',
    'Log in to answer': 'Einloggen, um zu antworten',
    'No more comments': 'Keine weiteren Kommentare',
    'No more responses': 'Keine weiteren Beiträge',
    "Let's talk about...": 'Lasst uns reden',

    // Moderation
    '🚧 **Moderation under construction:** We are still tuning auto-moderator triggering.': '🚧 **Moderation im Aufbau:** Wir justieren noch die Auslösung des Auto-Moderators.',
    'Consider rewording your post': 'Überlege, deinen Beitrag umzuformulieren',
    'Your post violates our [Community Guidelines](https://google.com).': 'Dein Beitrag verstößt gegen unsere [Gemeinschaftsrichtlinien](https://google.com).',
    'You are free to express any opinion, however you must do so in a respectful way that avoids personal attacks, assumes good intent, and backs up contentious claims with sources.': 'Du darfst jede Meinung äußern, musst dies jedoch auf eine respektvolle Weise tun, die persönliche Angriffe vermeidet, gute Absichten unterstellt und strittige Behauptungen mit Quellen untermauert.',
    'Sometimes our AI moderator triggers incorrectly. If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the moderator to accept a respectfully expressed opinion then please let us know.': 'Manchmal wird unser KI-Moderator fälschlicherweise ausgelöst. Wenn das passiert, versuche zuerst, deine Formulierungen offensichtlich respektvoller zu gestalten. Wenn es dir trotzdem nicht gelingt, dass der Moderator eine respektvoll geäußerte Meinung akzeptiert, lassen es uns bitte wissen.',
    'To provide transparency, we offer an anonymized [public database](https://google.com) of all moderation decisions.': 'Um Transparenz zu gewährleisten, bieten wir eine anonymisierte [öffentliche Datenbank](https://google.com) aller Moderationsentscheidungen an.',
    'Discuss with Human Moderator': 'Mit einem menschlichen Moderator diskutieren',
    'Updating...': 'Aktualisierung...',
    'Your response has been sent to a moderator for review': 'Deine Antwort wurde an einen Moderator zur Überprüfung gesendet',
    'You will be notified when the moderator makes a decision. In the meantime, you can look at the [transparency dashboard]({dashboardUrl}) to see where your post is in our moderation queue.': 
    'Du wirst benachrichtigt, wenn der Moderator eine Entscheidung trifft. In der Zwischenzeit kannst du auf dem [Transparenz-Dashboard]({dashboardUrl}) sehen, wo dein Beitrag in unserer Moderationswarteschlange steht.',
    'I understand': 'Ich verstehe',
    'We strive for a kind and constructive discussion space where everyone feels welcome to contribute their perspectives and lived experiences to the conversation. This discussion space values responses that are on-topic and relevant to the subject matter, including meaningful debate.':'Wir möchten einen freundlichen und konstruktiven Austausch ermöglichen, bei dem alle sich eingeladen fühlen, ihre Perspektiven und gelebten Erfahrungen in das Gespräch einzubringen. In diesen Gesprächsräumen schätzen wir Beiträge und Perspektiven, die auf das Thema bezogen und für das Thema relevant sind, einschließlich sinnvoller Diskussionen und Debatten.',
    'To foster a welcoming and respectful environment, this discussion space uses AI-aided moderation to enforce the following community guidelines:':'Um eine einladende, respektvolle Umgebung zu schaffen, in der alle sich willkommen und sicher fühlen, nutzen wir zur Moderation die Hilfe von automatisierten und KI-unterstützten Systemen. Sie helfen uns folgende Community-Regeln zur Sicherheit aller durchzusetzen:',
    'No uncivil, hateful, or illegal content': 'Keine unanständigen, hasserfüllten, oder illegalen Inhalte',
    'No comments that call for or celebrate harm': 'Keine Inhalte, die zu Gewalt aufrufen oder Gewalt verherrlichen',
    'No insults, mockery, threats, or personal attacks': 'Keine Beleidigungen, Spott, Verhöhnungen, Drohungen oder persönlichen Angriffe',
    'Refrain from hostile comments that attach negative labels to, or assume bad intent of specific groups of people': 'Keine feindseligen Beiträge, die anderen Personengruppen negative Label oder verallgemeinernde negative Eigenschaften zuschreiben oder bösartige Absichten unterstellen',
    'Back up contentious claims with sources': 'Belegen Sie umstrittene Behauptungen mit Quellen',
    'No spam or commercial content': 'Kein Spam oder kommerzielle Inhalte',
    "Your post may have violated the following Community Guidelines:": "Dein Beitrag verstoßt gegen die folgenden Community-Regeln:",
    "Need help?": "Helfen Sie?",
    "Thank you!": "Danke!",
    'Your response has been sent to a moderator for review.': 'Deine Antwort wurde an einen Moderator zurprüfung gesendet',
    "📫 What happens next": "📫 Was passiert als nächstes",
    '✨ We use AI for moderation': '✨ Wir nutzen AI für moderatoren',
    'AI helps us to manage moderation, but sometimes it triggers incorrectly. If you are unable to get our AI to accept a respectfully expressed opinion, please send it to our moderators for review.': 'AI helfen uns, die Moderation zu verwalten, aber manchmal erzeugt es fehlerhafte. Wenn Sie die Moderationsentscheidung nicht akzeptieren, senden Sie sie an unsere Moderatoren für Überprüfung.',
    'Message to moderator...': 'Nachricht an Moderator ...',
    'Please explain why we should publish your post.': 'Erklären Sie, warum wir deinen Beitrag zurückgeben sollte.',
    "Done" : "Erledigt",
    'Respect the privacy of others and do not include personal information in your contributions': 'Respektieren Sie die Privatsphäre anderer und geben Sie keine persönlichen Daten preis.',
    'Your response is being reviewed by AI to ensure it follows our Community Guidelines': 'Deine Antwort wird von einer KI überprüft, um sicherzustellen, dass sie unseren Community-Regeln entspricht.',
    
    // Comment Slider
    'Strongly yes': 'Stark ja',
    'Yes with reservations': 'Ja, aber mit Vorbehalten',
    'It\'s complicated': 'Es ist kompliziert',
    'No with reservations': 'Nein, aber mit Vorbehalten',
    'Strongly no': 'Stark nein',
    'Clear Filter': 'Filter löschen',
    'Your current public response': 'Deine aktuelle öffentliche Antwort',
    'Showing {responseCount} public responses and {voteCount} anonymous votes': 'Anzeige von {responseCount} öffentlichen Antworten und {voteCount} anonymen Stimmen.',
    'Tap to respond': 'Tippe, um zu antworten',
    'Slide to respond:': 'Zum Antworten schieben:',
    'Explain your response (optional)': 'Erkläre deine Antwort (optional)',
    'Public response': 'Öffentlicher Beitrag',
    'Anonymous vote': 'Anonyme Stimme',
    'Agree/Disagree': 'Zustimmen/Widersprechen',
    'Explain your response': 'Erkläre deine Antwort',
    'Share your thoughts... (optional)': 'Teile deine Gedanken... (optional)',

    // Question Screen
    'responses': 'Beiträge',
    'response': 'Beitrag',
    'Update your response': 'Bearbeite deinen Beitrag',
    'Only visible to you': 'Nur für dich sichtbar',
    '🔍 Under Review': '🔍 In Überprüfung',
    'Share': 'Teilen',
    'Save': 'Speichern',
    'Your current response': 'Dein aktueller Beitrag',
    'There are no responses yet. Start the conversation!': 'Es gibt noch keine Antworten. Beginne die Konversation!',

    // Single Question
    'Choose a Question (Admin)': 'Wähle eine Frage (Admin)',
    'Choose a question': 'Wähle eine Frage',
    'Ask a question...': 'Stelle eine Frage...',
    'Choose': 'Wählen',
    'Chosen': 'Gewählt',
    'Choose a different question (admin)': 'Wähle eine andere Frage (Admin)',
    'Write your answer...': 'Schreibe deine Antwort...',
    'no answers yet': 'noch keine Antworten',

    // Multi question
    'Choose Questions for this Article': 'Wählen deine Fragen für diesen Artikel',
    'Edit questions (admin)': 'Fragen bearbeiten (Admin)',
    'Related Conversations': 'Verwandte Konversationen',
    'Add Question': 'Frage hinzufügen',
    'Editorial Question': 'Redaktionelle Frage',
    'Adding...' : 'Hinzufügen...',
    'Related Conversation': 'Verwandte Konversation',
    'Write the first response': 'Schreibe die erste Antwort',
    'Join': 'Beitreten',
    
    // Feature Module Names
    'Comment Slider': 'Kommentar-Slider',
    'Article Questions': 'Artikelfragen',
    'Pre-Moderation': 'Vor-Moderation',
    'Question Byline': 'Fragen-Verfasserzeile',

    // Moderation
    '**Avoid uncivil or disrespectful language**. You can express any opinion, however you must do so in a respectful way.': '**Vermeide unhöfliche oder respektlose Sprache**. Du kannst jede Meinung äußern, musst dies jedoch auf respektvolle Weise tun.',
    '**Avoid calls for harm or celebration of harm**. You can advocate for actions that would have negative consequences for some people, but harm should not be the primary goal.': '**Vermeide Aufrufe zu Schaden oder das Feiern von Schaden**. Du kannst dich für Maßnahmen einsetzen, die negative Folgen für manche Menschen haben würden, aber Schaden sollte nicht das Hauptziel sein.',
    '**Do not imply that someone has bad intent**. Assume they have good intentions and critique their ideas and their actions instead.': '**Gehe nicht davon aus, dass andere schlechte Absichten haben**. Gehe davon aus, dass sie gute Absichten haben und kritisiere stattdessen ihre Ideen und ihr Handeln.',
    '**Avoid attacking the character of others**. Critique their ideas and their actions instead.': '**Vermeide es, den Charakter anderer anzugreifen**. Kritisiere stattdessen ihre Ideen und ihr Handeln.',
    '**Avoid snark, sarcasm, and mockery**': '**Vermeide Sarkasmus und Spott**',
    '**Controversial claims must provide a source URL**': '**Kontroverse Behauptungen müssen eine Quell-URL angeben**', 
    '**Be sure to write your response in a way that those who disagree with will be willing to listen to**': '**Stelle sicher, dass deine Antwort so formuliert ist, dass diejenigen, die nicht zustimmen, bereit sind zuzuhören**',

    '⚠️ Take another look': '⚠️ Nochmal ansehen',
    'Your post may violate our [Community Guidelines](https://example.com).': 'Dein Beitrag verstößt möglicherweise gegen unsere [Gemeinschaftsrichtlinien](https://example.com).',
    '**Sometimes our AI triggers incorrectly**': '**Manchmal wird unser KI-Moderator fälschlicherweise ausgelöst**',
    'If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the AI to accept a respectfully expressed opinion then please send it to our human moderator.': 'Wenn das passiert, versuche zuerst, deine Formulierungen offensichtlich respektvoller zu gestalten. Wenn es dir trotzdem nicht gelingt, dass der Moderator eine respektvoll geäußerte Meinung akzeptiert, sende sie bitte an unseren menschlichen Moderator.',
    'To provide transparency, we offer an anonymized [public database]({dashboardUrl}) of all moderation decisions.': 'Um Transparenz zu gewährleisten, bieten wir eine anonymisierte [öffentliche Datenbank]({dashboardUrl}) aller Moderationsentscheidungen an.',
    'Send to moderator': 'An Moderator senden',
    'Message to moderator*': 'Nachricht an Moderator*',
    'Please explain your response (required)': 'Bitte erläutere deine Antwort (erforderlich)',
    'Cancel': 'Abbrechen', 
    'Delete': 'Löschen', 
    'Rejected': 'Abgelehnt', 
    'Approved': 'Genehmigt', 
    'Awaiting Decision': 'Entscheidung ausstehend', 
    'Moderation Queue': 'Moderations-Warteschlange', 
    
    'Moderator Explanation': 'Erklärung des Moderators',
    'Explain your decision...': 'Erkläre deine Entscheidung...', 
    'Approve': 'Genehmigen', 
    'Reject': 'Ablehnen', 
    '**Message to moderator**: {appealText}': 'Nachricht an den Moderator: {appealText}',
    'Moderation Transparency Dashboard': 'Transparentes Moderations-Dashboard', 
    
    //Blacklist-Feature
    'Blacklisted Terms': 'Blacklist-Begriffe', 
    'Blacklist is empty.': 'Blacklist ist leer.',
    'Blacklist': 'Blacklist' ,
    'Select': 'Auswählen', 
    'Deselect': 'Abwählen', 
    'Please enter at least one valid term.': 'Bitte gib mindestens einen gültigen Begriff ein.', 
    'The following term(s) already exist in the blacklist.': 'Die folgenden Begriffe existieren bereits in der Blacklist.',
    'Adding term(s)...': 'Begriffe werden hinzugefügt...',
    'Added term(s) successfully!': 'Begriffe erfolgreich hinzugefügt!', 
    'Deleting term(s)...': 'Begriffe werden gelöscht...',
    'Deleted term(s) successfully!': 'Begriffe erfolgreich gelöscht!',
    'Term(s) are already in blacklist': 'Begriff(e) sind bereits auf der Blacklist',
    'Enter new terms, separated by new lines': 'Neue Begriffe eingeben, getrennt durch Zeilenumbrüche', 
    'Add to blacklist': 'Zur Blacklist hinzufügen', 
    'Hold Shift and double-click to select or deselect multiple terms.': 'Umschalttaste gedrückt halten und doppelklicken, um mehrere Begriffe aus- oder abzuwählen.', 
    'Delete selected': 'Ausgewählte Begriffe löschen', 
    'Delete terms?': 'Begriffe wirklich löschen?',
    'Do you really want to delete the selected term(s)? This action cannot be undone.': 'Möchtest du die ausgewählten Begriffe wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.',

    // Reactions
    'Thank you': 'Danke',
    'New to me': 'Neu für mich',
    'Respect': 'Respekt',
    'Disagree': 'Nicht einverstanden',
    'Best': 'Am besten',
    'Provocative': 'Provokativ',
    'Clarifying': 'Klärend',

    // Representing Perspectives
    'Perspective Editor (Admin)': 'Perspektiven-Editor (Admin)',
    'Perspective List': 'Perspektivenliste',
    'What perspectives are important to this conversation? (newline separated)': 'Welche Perspektiven sind wichtig für diese Konversation? (durch Zeilenumbruch getrennt)',
    "Add your perspective": "Füge deine Perspektive hinzu",
    "Perspectives": "Perspektiven",
    "We haven't heard from these perspectives yet": "Wir haben noch nichts von diesen Perspektiven gehört",
    'Add a response': 'Eine Antwort hinzufügen',
    'How do you relate to this issue?': 'Wie stehst du zu diesem Thema?',
    'Sort by perspective': 'Nach Perspektive sortieren',
    'Apply': 'Anwenden',
    'Show more responses': 'Mehr Antworten anzeigen',
    'Edit perspectives (Admin)': 'Perspektiven bearbeiten (Admin)',
    'What perspectives do you want to see first?': 'Welche Perspektiven möchtest du zuerst sehen?',
    "We'd particularly like to hear from:": "Welche Sicht hast Du auf das Thema?",
    "Add your own": "Füge deine eigene hinzu",
    "Write in your own": "Füge deine eigene hinzu",
    "Write in a perspective": "Eigene Perspektive vorschlagen",


    // Other
    'No main screen defined.': 'Kein Hauptbildschirm definiert.',


    // Video Voting - Intro screen
    'Enable voting': 'Voting aktiv',
    'Vote anonymously': 'Anonymisiert abstimmen',
    'Start Video': 'Video starten',
    'This video contains audience questions that you can participate in. You can vote anonymously, skip individual questions, or disable this feature. In the ' :
    'Dieses Video enthält Zuschauerfragen, an denen du dich beteiligen kannst. Du kannst diese Funktion deaktivieren, anonym abstimmen und einzelne Fragen überspringen. Über die ',
    'settings': 'Einstellungen',
    ' you can deactivate it for all videos.': ' kannst du die Funktion generell deaktivieren.',
    "You have to be logged in to use this feature.\nThe video will play as usual and you won't see any votings.\n**Click here to log in**." : 
    "Du musst angemeldet sein, um diese Funktion nutzen zu können.\nDas Video wird wie gewohnt abgespielt und du siehst keine Abstimmungen.\n**Klicke hier, um dich einzuloggen**.",

    // Video Voting - Voting flow
    'Discuss with others': 'Tausche dich mit anderen aus',
    'Continue': 'Fortsetzen',
    'Take a look at the voting in the overview': 'Sieh dir die Abstimmung in der Übersicht an',
    "What do you think about it now?": "Wie denkst du jetzt darüber?",

    // Video Voting - Sidebar
    'Video Voting': 'Video Voting',
    'Voting Results': 'Abstimmungsergebnisse',
    'All responses': 'Alle Kommentare',
    'Votings': 'Abstimmungen',
    "Time:": "Zeit:",
    'END': 'ENDE',
    "No voting data available.": "Keine Abstimmungsdaten vorhanden",
    'Go to video': 'Zum Video',

    // Video Voting - User profile
    "Settings": "Einstellungen",
    "Enable voting for all videos": "Voting für alle Videos aktivieren",

    // Video Voting - Administrative text
    'Manage Video Voting (Admin)': 'Video Voting verwalten (Admin)',
    "Please create default question threads first.": "Bitte erstelle zuerst die Standardfragen-Threads.",
    "Create Default Questions": "Standardfragen erstellen",
    "Create a Video Voting for this Article": "Erstelle eine Videoabstimmung für diesen Artikel",
    "This article does not have a video voting yet.": "Dieser Artikel hat noch keine Videoabstimmung.",
    "Individual Questions": "Individualfragen",
    "Connected Questions": "Verlaufsfragen",
    "Endometriosis (German)": "Endometriose (Deutsch)",
    "Endometriosis (English)": "Endometriose (Englisch)",
    "Open relationships (German)": "Offene Beziehungen (Deutsch)",
    "Open relationships (English)": "Offene Beziehungen (Englisch)",
    "Create Video Voting": "Videoabstimmung erstellen",
    "Delete Video Voting": "Videoabstimmung löschen",
    "The video voting template is missing": "Die Vorlage für die Videoabstimmung fehlt",
    "Video voting is not enabled for this video.": "Video Voting ist für dieses Video nicht aktiviert.",
    'Video voting is enabled for this video.' : 'Video Voting ist für dieses Video aktiviert.',
    'Video voting is enabled for this video. To participate, please start the video.': 'Video Voting ist für dieses Video aktiviert. Um teilzunehmen musst du eingeloggt sein.',

    // Conversation History
    'Conversation history': 'Konversationsverlauf',
    'Visible to others': 'Sichtbar für andere',
    'Your conversation history is always visible to admins and moderators.': 'Dein Konversationsverlauf ist immer für Admins und Moderatoren sichtbar.',
    'Visible only to admins': 'Nur für Admins sichtbar',
    'This user has not made their conversation history public.': 'Dieser Benutzer hat seinen Konversationsverlauf nicht öffentlich gemacht.',

    // Conversation Helper
    "Help history": "Hilfeverlauf",
    "Conversation Help": "Gesprächshilfe",
    "Hello everyone!": "Hallo zusammen!",
    "Open dashboard (admin)": "Admin-Dashboard öffnen",
    "Conversation Helper": "Gesprächshelfer",
    "Add-ons": "Erweiterungen",
    "Reactions": "Reaktionen",
    "Help History": "Hilfeverlauf",
    "Conversation Summary": "Gesprächszusammenfassung",
    "Private Comment Feedback": "Privates Kommentar-Feedback",
    "Timing of Analysis": "Analysezeitpunkt",
    "Automatic analysis": "Automatische Analyse",
    "Analyze after every...": "Analysiere nach jedem...",
    "1 comment": "Kommentar",
    "3 comments": "3. Kommentar",
    "5 comments": "5. Kommentar",
    "10 comments": "10. Kommentar",
    "Only manual analysis": "Nur manuelle Analyse",
    "Update guidance if there are no problems": "Hinweis aktualisieren, wenn es keine Probleme gibt",
    "Update based on comments...": "Kommentarbasiert aktualisieren...",
    "Never": "Nie",
    "Every comment": "Nach jedem Kommentar",
    "Every 3 comments": "Alle 3 Kommentare",
    "Every 5 comments": "Alle 5 Kommentare",
    "Every 10 comments": "Alle 10 Kommentare",
    "Update based on time...": "Zeitbasiert aktualisieren...",
    "Immediately": "Sofort",
    "Every 10 min": "Alle 10 Minuten",
    "Every 30 min": "Alle 30 Minuten",
    "Every hour": "Jede Stunde",
    "Scope of Analysis": "Analyseumfang",
    "Analyze...": "Analysiere...",
    "All comments": "Alle Kommentare",
    "Most recent x% of comments": "Neuste x% der Kommentare",
    "New comments since last analysis": "Neue Kommentare seit letzter Analyse",
    "Use percentage...": "Wähle Prozentsatz...",
    "Most recent 10%": "Neuste 10%",
    "Most recent 25%": "Neuste 25%",
    "Most recent 50%": "Neuste 50%",
    "Apply percentage...": "Verwende Prozentsatz...",
    "Always": "Immer",
    "After 5 comments": "Nach 5 Kommentaren",
    "After 10 comments": "Nach 10 Kommentaren",
    "Auto-Moderator Personality": "Auto-Moderator Persönlichkeit",
    "Use personality...": "Verwende Persönlichkeit...",
    "Personality A": "Persönlichkeit A",
    "Personality B": "Persönlichkeit B",
    "Personality C": "Persönlichkeit C",
    "Conversation Helper Dashboard": "Gesprächshelfer Dashboard",
    "Related Thread": "Zugehörige Unterhaltung",
    "Current Guidance": "Aktueller Hinweis",
    "This guidance message is visible to everyone.": "Dieser Hinweis ist für alle sichtbar.",
    "This message was generated because...": "Dieser Hinweis wurde generiert, weil...",
    "Generate new guidance": "Neuen Hinweis generieren",
    "Conversation Analysis": "Gesprächsanalyse",
    "Analyze conversation": "Gespräch analysieren",
    "Result: ": "Ergebnis: ",
    "Problems detected!": "Probleme gefunden!",
    "No problems detected.": "Keine Probleme gefunden.",
    "Explanation": "Erklärung",
    "Conversation Stats": "Gesprächsstatistiken",
    "- Last conversation analysis: **{time}**": "- Letzte Gesprächsanalyse: **{time}**",
    "- Last conversation analysis: **None**": "- Letzte Gesprächsanalyse: **Keine**",
    "- **{count} check(s)** for problems performed": "- **{count} Gesprächsanalyse(n)** durchgeführt",
    "- **{count} guidance message(s)** generated": "- **{count} Hinweis(e)** generiert",
    "- **{count} new comment(s)** since last conversation analysis": "- **{count} neue(r) Kommentar(e)** seit der letzten Analyse",

    // np-platform overwrites
    'Share your thoughts' : "Dein Beitrag zum Thema",
    'Share your thoughts...': 'Dein Beitrag zum Thema...',
    'Be the first to share your thoughts' : "Sei die erste Person, die ihre Gedanken teilt",
    "Update": "Posten",
    "Post": "Posten",

    // Context Entry Points
     "You are joining from:": "Du bist von:",
     "Joining from:": "Du bist von:",
     "Read article": "Artikel lesen",
     "Joined from": "Beigetreten von",

    // Reading List / Topic
    "Reading List": "Weitere Inhalte",
    "Learn more about this topic": "Erfahre mehr zu diesem Thema",
    "Discussion topic": "Diskussions-Thema",
    "participant": "Teilnehmende",
    "participants": "Teilnehmer/innen",
    "Show more": "Mehr anzeigen",

}

