import { PadBox } from "np-platform-client/component/basics";
import { Tag } from "np-platform-client/component/button";
import { colorBlueBackground, colorGreyPopupBackground, colorPink, colorTextGrey } from "np-platform-client/component/color";


export const HideRejectedFeature = {
    key: 'hide_rejected',
    name: 'Hide Rejected',
    config: {
        commentFilters: [filterFunc],
        replyFilters: [filterFunc],
        commentTopWidgets: [InReviewTag],
        composerTopWidgets: [InReviewTag],
        commentBodyStylers: [bodyStyler]
    }
}

function bodyStyler({comment}) {
    if (comment.violates || comment.inReview) {
        return {color: colorTextGrey, backgroundColor: colorGreyPopupBackground, padding: 12, borderRadius: 8};
    } else {
        return null;
    }
}

function filterFunc({datastore, isAdmin, comment}) {
    if (
            (comment.inReview || comment.violates) && 
            !isAdmin &&
            !(comment.replyTo && comment.from == datastore.getPersonaKey())) {
        return false;
    } else {
        return true;
    }
}

function InReviewTag({comment}) {
    if (comment.inReview) {
        return <PadBox bottom={20}>
            <Tag compact color={colorBlueBackground} label='🔍 Under Review' />
        </PadBox>
    } else if (comment.violates && comment.humanJudgement) {
        return <PadBox bottom={20}>
            <Tag compact color={colorPink} label='🚫 Rejected by Human Moderator' />
        </PadBox>
    } else {
        return null;
    }
}
