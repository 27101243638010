import { HorizBox, HoverView, Pad, PadBox } from "np-platform-client/component/basics";
import { Tag } from "np-platform-client/component/button";
import { UtilityText } from "np-platform-client/component/text"
import { StyleSheet, View } from 'react-native';
import { FacePile } from 'np-platform-client/component/people';
import { PercentageBar, PercentageBarContainer } from './videovotingPercentageBars';
import { colorAccent, colorBlack, colorGreyBorder, colorGreyHover, colorGreyPopupBackground } from "np-platform-client/component/color";
import { Checkmark } from "@carbon/icons-react";


// TODO This is copied from the poll picker component
// The radio button needs to be an own component for everyone
function OptionRadioButton({ selected, onPress, testID }) {
    const s = OptionRadioButtonStyle
    return <HoverView testID={testID}
        onPress={onPress}>
        <View style={[s.rightIcon, selected ? s.iconSelected : '']}>
            <Checkmark color='white' />
        </View>
    </HoverView>
}

const OptionRadioButtonStyle = StyleSheet.create({
    body: {
        flex: 1
    },
    rightIcon: {
        borderColor: colorGreyBorder,
        borderRadius: 100,
        borderWidth: 1,
        padding: 4
    },
    iconSelected: {
        backgroundColor: colorBlack,
        borderColor: colorBlack
    }
})

/**
 * Displays one answer option of a question with a specific range of votes
 */
export function VideoVotingOption({ optionText, selected, voteCount=0, allVoteCount=0, voterKeys=[], onSelect, votingEnabled, showResults }) {

    const percentage = allVoteCount > 0 ? voteCount*100/allVoteCount : 0

    return <PadBox vert={12}>
        <HorizBox center>
        <View style={{ flex: "1" }}>
            <HorizBox spread center>
                <View style={{ flex: "1" }}>
                    <UtilityText text={optionText}></UtilityText>
                </View>
                <View style={{ opacity: (showResults) ? 1 : 0}}>
                    <HorizBox center >
                        <View style ={{position: "relative", left: 10}}>
                            <FacePile type="tiny" userIdList={voterKeys}></FacePile>  
                        </View>
                        <View style={videoVotingOptionStyle.tag}>
                                <UtilityText type="small" text={voteCount.toString()}></UtilityText> 
                        </View>
                        <View></View>
                    </HorizBox>
                </View>
            </HorizBox>
            <Pad size={4} />
            <View style={[{flexGrow: 1, position: "relative", opacity: (showResults) ? 1 : 0 }, videoVotingOptionStyle.percentageBarBase]}>
                <PercentageBarContainer backgroundColor={"#d6d6d6"}>
                    <PercentageBar percentages={[percentage]} colors={[selected ? colorAccent:"#2E2E2E"]}></PercentageBar>
                </PercentageBarContainer>
            </View>  
        </View>
        { votingEnabled ? <View style={{marginLeft: 16}}><OptionRadioButton testID={optionText} type="radio" readOnly selected={selected} onPress={onSelect}></OptionRadioButton></View> : null }
    </HorizBox>
    </PadBox>
    
    
}

const videoVotingOptionStyle = StyleSheet.create({
    percentageBarBase: {
        height: 7,
        borderRadius: 5.5,
        overflow: "hidden"
    },
    tag: {
        display: "flex",
        borderRadius: 100,
        borderWidth: 2,
        borderColor: colorGreyPopupBackground,
        backgroundColor: "#C3C3C3",
        paddingHorizontal: 6,
        height: 24,
        justifyContent: "center",
        alignItems: "center",
    }
})