import { Narrow } from "np-platform-client/component/basics"
import { DemoSection } from "np-platform-client/component/demo"
import { Datastore } from "np-platform-client/util/datastore";
import { VideoVotingFeature } from "../../../feature/zdf/videovoting/VideoVotingArticleFeature";
export function VideoVotingArticleFeatureDemo() {

    return (
        <Narrow>
            <DemoSection label="Video Voting Teaser (no video voting enabled)">
                <Datastore
                    isAdmin={true}>
                    <VideoVotingFeature.config.teaser/>
                </Datastore>
            </DemoSection>
            <DemoSection label="Video Voting Teaser (video voting enabled)">
                <Datastore
                    globals={{
                        videoVotingEnabled: true,
                        questionThreadKey: "someQuestionKey"
                    }}
                    isAdmin={true}>
                    <VideoVotingFeature.config.teaser/>
                </Datastore>
            </DemoSection>
            <DemoSection label="Voting Voting Admin Screen (no video voting created yet)">
                <Datastore
                    globals={{}}
                    isAdmin={true}>
                    <VideoVotingFeature.config.adminScreen/>
                </Datastore>
            </DemoSection>
            <DemoSection label="Voting Voting Admin Screen (video voting exists)">
                <Datastore
                    globals={{
                        videoVotingEnabled: true,
                        questionKey: "someQuestionKey"
                    }}
                    isAdmin={true}>
                    <VideoVotingFeature.config.adminScreen/>
                </Datastore>
            </DemoSection>
        </Narrow>
    );
}