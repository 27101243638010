import { Narrow } from "np-platform-client/component/basics";
import { EstimationSlider } from "../../../contrib/zdf/videovoting/estimationSlider";
import { DemoSection } from "np-platform-client/component/demo";
import { CoinSplash } from "../../../contrib/zdf/videovoting/videoVotingAnimations";


export function EstimationSliderDemo(){

    const Animation = ()=>(<CoinSplash/>)

    return <Narrow>
        <DemoSection label={"Regular starting at the beginning"}>
            <EstimationSlider value={0} min={0} max={2000} stepSize={100} onChangeValue={(c)=>console.log("c",c)}></EstimationSlider>
        </DemoSection>
        <DemoSection label={"With snappy handle while dragging"}>
            <EstimationSlider snapOnMove value={0} min={0} max={2000} stepSize={100} onChangeValue={(c)=>console.log("c",c)}></EstimationSlider>
        </DemoSection>
        <DemoSection label={"Set inital position to custom value"}>
            <EstimationSlider value={900} min={0} max={2000} stepSize={100} onChangeValue={(c)=>console.log("c",c)}></EstimationSlider>
        </DemoSection>
        <DemoSection label={"With Animation"}>
        <EstimationSlider value={0} min={0} max={2000} stepSize={100}  onChangeValue={(c)=>{}} AnimationComponent={Animation}></EstimationSlider>
        </DemoSection>
        <DemoSection label={"Custom symbol"}>
            <EstimationSlider value={0} min={0} max={2000} stepSize={100} trailingSymbol="$" onChangeValue={(c)=>console.log("c",c)}></EstimationSlider>
        </DemoSection>
    </Narrow>
}