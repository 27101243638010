import { TabNavigationDemo } from "../../demo/zdf/tabnavigationdemo"
import { VideoVotingOptionDemo } from "../../demo/zdf/videovoting/videovotingoptiondemo"
import { PercentageBarDemo } from "../../demo/zdf/percentagebardemo"
import { CountdownDemo } from "../../demo/zdf/countdowndemo"
import { CircularBarDemo } from "../../demo/zdf/circularbardemo"
import { VideoPlayerStateContextProviderDemo } from "../../demo/zdf/customvideocontroldemo"
import { PositionableVideoOverlayDemo } from "../../demo/zdf/positionablevideooverlaydemo"
import { QuestionResultConnectedDemo, QuestionResultIndividualDemo } from "../../demo/zdf/videovoting/videovotingquestionresultdemo"
import { VideoVotingQuestionDialogDemo, videovotingQuestionDialogStorySets } from "../../demo/zdf/videovoting/videovotingquestiondialogdemo"
import { ProfileSettingsFullDemo } from "../../demo/zdf/profilesettingsdemo"
import { VideoLinkDemo } from "../../demo/zdf/videolinkdemo"
import { PrivateThankYouMessageDemo, PrivateCommentFeedbackFullDemo } from "../../demo/zdf/privatecommentfeedbackdemo"
import { ConversationHelperFullDemo, ThoughtProvokingPromptAdminDemo, ThoughtProvokingPromptDemo } from "../../demo/zdf/conversationhelper/conversationhelperdemo"
import { VideoVotingArticleFeatureDemo } from "../../demo/zdf/videovoting/videovotingarticledemo"
import { VideoVotingIntroScreenStorySets } from "../../demo/zdf/videovoting/videovotinginitsessiondemo"
import { VideoVotingQuestionScreenStorySets } from "../../demo/zdf/videovoting/videovotingquestionscreendemo"
import { VideoVotingQuestionOverviewFeatureDemo, VideoVotingQuestionOverviewFeatureStorySets, VideoVotingQuestionOverviewFullStructureDemo } from "../../demo/zdf/videovoting/videovotingquestionoverview"
import { EstimationSliderDemo } from "./videovoting/videovotingestimationsliderdemo"
import { TypewriterDemo } from "../../demo/zdf/typewriterdemo"
import { BlacklistFeatureDemo } from "./moderation/blacklistdemo"


export const ZDFDemoFeature = {
    key: "demo_zdf",
    name: "Demo - ZDF",
    config: {
        componentSections: [
            {
                label: "ZDF",
                key: "zdf",
                pages: [
                    {
                        label: "Custom Video Control",
                        key: "customvideocontrol",
                        screen: VideoPlayerStateContextProviderDemo,
                        designUrl: null,
                    },
                    {
                        label: "Positionable Video Overlay",
                        key: "positionablevideooverlay",
                        screen: PositionableVideoOverlayDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14800&t=xWo4yvIu6txN2nCu-0",
                    },
                    {
                        label: "Circular Progress Bar",
                        key: "circularbar",
                        screen: CircularBarDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=xWo4yvIu6txN2nCu-0",
                    },
                    {
                        label: "Countdown Hook",
                        key: "countdown",
                        screen: CountdownDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=xWo4yvIu6txN2nCu-0",
                    },
                    {
                        label: "Percentage Bar",
                        key: "percentagebar",
                        screen: PercentageBarDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=sfCmlnUlQ3yQYkyv-0",
                    },
                    {
                        label: "Video Voting Option",
                        key: "videovotingoption",
                        screen: VideoVotingOptionDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=sfCmlnUlQ3yQYkyv-0",
                    },
                    {
                        label: "Video Voting Question Dialog",
                        key: "videovotingquestiondialog",
                        screen: VideoVotingQuestionDialogDemo,
                        storySets: videovotingQuestionDialogStorySets,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=T5gkaOoaJHS80PQM-0",
                    },
                    {
                        label: "Video Voting Intro Screen Flow",
                        key: "videovoting-flow-intro",
                        storySets: VideoVotingIntroScreenStorySets,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-37040&t=hLGgVovB0YEHjRM1-4",
                    },
                    {
                        label: "Video Voting Question Screen Flow",
                        key: "videovoting-flow-question",
                        storySets: VideoVotingQuestionScreenStorySets,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-2031&t=hLGgVovB0YEHjRM1-4",
                    },
                    {
                        label: "Video Voting Article Feature",
                        key: "videovoting-feature-article",
                        screen: VideoVotingArticleFeatureDemo,
                        designUrl: null,
                    },
                    {
                        label: "Video Voting Overview Feature",
                        key: "videovoting-feature-overview",
                        screen: VideoVotingQuestionOverviewFeatureDemo,
                        storySets: VideoVotingQuestionOverviewFeatureStorySets,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=181-57174&t=hLGgVovB0YEHjRM1-4",
                    },
                    {
                        label: "Voting Result - Individual Question",
                        key: "videovotingquestionresultindividual",
                        screen: QuestionResultIndividualDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14962&t=mr2JGbayv3pyFz0T-0",
                    },
                    {
                        label: "Voting Result - Connected Question",
                        key: "videovotingquestionresultconnected",
                        screen: QuestionResultConnectedDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=Hbdjgdaa66pDfl0e-0",
                    },
                    {
                        label: "Tab Navigation",
                        key: "tabnavigation",
                        screen: TabNavigationDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14854&t=ZLRDLJDAgLQ3gDiB-0",
                    },
                    {
                        label: "Video Link",
                        key: "videolink",
                        screen: VideoLinkDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14854&t=mr2JGbayv3pyFz0T-0",
                    },
                    {
                        label: "Thought-Provoking Prompt",
                        key: "thoughtprovokingprompt",
                        screen: ThoughtProvokingPromptDemo,
                        designUrl:
                            "https://www.figma.com/design/VDCebWSu7lU0GynG1qmtF5/Conversation-Helper---Testing?node-id=368-19878&t=AJVqkONSwh2ew3R4-0",
                    },
                    {
                        label: "Thought-Provoking Prompt Admin",
                        key: "thoughtprovokingpromptadmin",
                        screen: ThoughtProvokingPromptAdminDemo,
                    },
                    {
                        label: "Private Thank-You Message",
                        key: "thankyoumessage",
                        screen: PrivateThankYouMessageDemo,
                        designUrl:
                            "https://www.figma.com/design/VDCebWSu7lU0GynG1qmtF5/Conversation-Helper---Testing?node-id=368-19878&t=AJVqkONSwh2ew3R4-0",
                    },
                    {
                        label: "Video Voting Slider Estimator",
                        key:"videovotingestimatorslider",
                        screen: EstimationSliderDemo,
                        designUrl: "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-45098&node-type=FRAME&t=DXr8xjnZlBtSM16m-0"
                    },
                    {
                        label: "Typewriter Demo",
                        key: "typewriter",
                        screen: TypewriterDemo,
                    },
                ],
            },
        ],
        structureSections: [
            {
                label: "ZDF",
                key: "zdf",
                pages: [
                    { label: "Conversation Helper", key: "conversationhelper", screen: ConversationHelperFullDemo },
                    {
                        label: "Private Comment Feedback",
                        key: "privatecommentfeedback",
                        screen: PrivateCommentFeedbackFullDemo,
                    },
                    {
                        label: "Profile Settings",
                        key: "profilesettingsfull",
                        screen: ProfileSettingsFullDemo,
                    }
                ],
            },
        ],
        featureSections: [
            {
                label: "ZDF",
                key: "zdf",
                pages: [
                    {
                        label: "Question - Video Voting Overview",
                        key: "question-feature-videovoting-overview",
                        screen: VideoVotingQuestionOverviewFullStructureDemo,
                    },
                    {
                        label: 'Question - Blacklist',
                        key: 'blacklistdemo',
                        screen: BlacklistFeatureDemo,
                    },
                ],
            },
        ],
    },
};