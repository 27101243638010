import { CLICK } from "np-platform-client/component/demo";
import { VIDEO_VOTING_SESSION_STATES } from "../../../contrib/zdf/videovoting/videoVotingSession";
import { DemoVideoVotingContainer, videoVotingDemoData } from "./videovotingdemo.util";

const templateConnected = videoVotingDemoData.templateConnected;
const templateIndividual = videoVotingDemoData.templateIndividual;

const globalsIndividual = {
    videoVotingTemplate: templateIndividual
}

const globalsConnected = {
    videoVotingTemplate: templateConnected
}

const showQuestionStateIndividual = {
    personaKey: "a",
    template: templateIndividual,
    state: VIDEO_VOTING_SESSION_STATES.showQuestion,
    currentQuestionKey: templateIndividual.questions[1].key,
    anonymous: false,
    questions: {
        [templateIndividual.questions[0].key] :{answered:true}
    }
}

const finalQuestionStateIndividual = {
    personaKey: "a",
    template: templateIndividual,
    state: VIDEO_VOTING_SESSION_STATES.waitForQuestion,
    currentQuestionKey: null,
    anonymous: false,
    questions: {
        [templateIndividual.questions[1].key] :{answered:true},
        [templateIndividual.questions[2].key] :{answered:true}
    }
}

const finalQuestionStateConnected = {
    personaKey: "a",
    template: templateConnected,
    state: VIDEO_VOTING_SESSION_STATES.waitForQuestion,
    currentQuestionKey: null,
    anonymous: false,
    questions: {
        [templateConnected.questions[0].subQuestions[0].key] :{answered:true},
        [templateConnected.questions[0].subQuestions[1].key] :{answered:true},
    }
}

const showQuestionStateConnected = {
    personaKey: "a",
    template: templateConnected,
    state: VIDEO_VOTING_SESSION_STATES.showQuestion,
    currentQuestionKey: templateConnected.questions[0].subQuestions[0].key,
    anonymous: false,
    questions: {
    }
}

const videoStateLoaded= {
    isPlaying: false,
    currentTime: 600,
    duration: 900,
    ended:false,
    videoLoaded: true
}

const videoStateSecondQuestionIndividual = {
    ...videoStateLoaded,
    currentTime: 600
}

const videoStateFirstQuestionConnected = {
    ...videoStateLoaded,
    currentTime: 490,
}

const videoStateFinalQuestion = {
    ...videoStateLoaded,
    currentTime: 830
}

export function VideoVotingQuestionScreenStorySets() {return [
    {
        label: 'Show individual question',
        instanceKey: 'a', personaKey: 'a',
        sessionData: {
            'videoVotingSessionState': showQuestionStateIndividual
        },
        globals: globalsIndividual,
        content: <DemoVideoVotingContainer videoState={videoStateSecondQuestionIndividual} showStats />,
        serverCall: {eventlog: {
            logEvent: () => {}
        }},
        stories: [
            {label: 'Vote for option and continue', actions: [
                CLICK(templateIndividual.questions[1].options[0].text),
                CLICK("Continue"),
            ]},
            {label: 'Skip to next question', actions: [
                CLICK("Continue"),
                CLICK("FWRD"),
                CLICK("FWRD"),
            ]
            },
            {label: 'Skip question and navigate back', actions: [
                CLICK("Continue"),
                CLICK("FWRD"),
                CLICK("PREV"),
            ]}
        ]
    },
    {
        label: 'Show connected question',
        instanceKey: 'a', personaKey: 'a',
        sessionData: {
            'videoVotingSessionState': showQuestionStateConnected
        },
        globals: globalsConnected,
        content: <DemoVideoVotingContainer videoState={videoStateFirstQuestionConnected} showStats />,
        serverCall: {eventlog: {
            logEvent: () => {}
        }},
        stories: [
            {label: 'Vote for option and continue', actions: [
                CLICK(templateConnected.questions[0].options[0].text),
                CLICK("Continue"),
            ]},
            {label: 'Skip to next connected question', actions: [
                CLICK("Continue"),
                CLICK("FWRD"),
            ]},
        ]
    },
    {
        label: 'Final individual question',
        instanceKey: 'a', personaKey: 'a',
        sessionData: {
            'videoVotingSessionState': finalQuestionStateIndividual
        },
        globals: globalsIndividual,
        content: <DemoVideoVotingContainer videoState={videoStateFinalQuestion} showStats />,
        serverCall: {eventlog: {
            logEvent: () => {}
        }},
        stories: [
            {label: 'Skip to final question and finish voting', actions: [
                CLICK("FWRD"),
                CLICK("Continue"),
            ]}
        ]
    },
    {
        label: 'Final connected question',
        instanceKey: 'a', personaKey: 'a',
        sessionData: {
            'videoVotingSessionState': finalQuestionStateConnected
        },
        globals: globalsConnected,
        content: <DemoVideoVotingContainer videoState={videoStateFinalQuestion} showStats />,
        serverCall: {eventlog: {
            logEvent: () => {}
        }},
        stories: [
            {label: 'Skip to final question and finish voting', actions: [
                CLICK("FWRD"),
                CLICK("Continue"),
            ]}
        ]
    },
]}