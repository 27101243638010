import { Banner } from "np-platform-client/component/banner";
import { HorizBox, Narrow, Pad } from "np-platform-client/component/basics";
import { colorGreyPopupBackground } from "np-platform-client/component/color";
import { DemoHeader, SpacedArray } from "np-platform-client/component/demo";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { useVideo, VideoPlayerStateContextProvider } from "../../contrib/zdf/videoPlayerStateContext";
import { SimpleVideo } from "../../contrib/zdf/videoPlayer";
import { View } from "react-native-web";
import { CTAButton } from "np-platform-client/component/button";

export function VideoPlayerStateContextProviderDemo() {
    return (
        <Narrow>
            <DemoHeader label="VideoPlayerStateContext" />

            <SpacedArray pad={10}>
                <Banner color={colorGreyPopupBackground}>
                    <SpacedArray pad={10}>
                        <Heading label="How to use VideoPlayerStateContext" />
                        <UtilityText
                            label={"useVideoFromArticle - enables the control of the main video on the article site"}
                        />
                    </SpacedArray>
                </Banner>

                <VideoPlayerStateContextProvider useVideoFromArticle={false}>
                    <SimpleVideo
                        src={"https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"}
                    />
                    <Pad size={10} />
                    <SimpleVideoControls />
                </VideoPlayerStateContextProvider>
            </SpacedArray>
        </Narrow>
    );
}

function SimpleVideoControls() {
    const video = useVideo();
    return (
        <View>
            <HorizBox>
                <CTAButton label="PLAY" onPress={video.playVideo}></CTAButton>
                <Pad size={10} />
                <CTAButton label="PAUSE" onPress={video.pauseVideo}></CTAButton>
            </HorizBox>
            <Pad size={10} />
            <UtilityText label={"Duration: " + video.duration}></UtilityText>
            <UtilityText label={"Current Time: " + video.currentTime}></UtilityText>
            <UtilityText label={"Is Playing?: " + video.isPlaying}></UtilityText>
            <UtilityText label={"Ended: " + video.ended}></UtilityText>
        </View>
    );
}