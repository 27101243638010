import { UtilityText } from "np-platform-client/component/text";
import { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import { getPrettyTimestamp, getSecondsFromPrettyTimestamp, usePlayedVideo, validateTimestamp } from "../videotime";
import { ClipPlayer } from "./clipplayer";
import { ValidatedTextField } from "../../contrib/zdf/text";

export function ClipEditor({ onChangeValue, currentClip }) {
    const currentVideo = usePlayedVideo();
    // console.log("ClipEditor currentVideo: ", currentVideo, " currentClip: ", currentClip);

    const [startValidState, setStartValidState] = useState(true);
    const [endValidState, setEndValidState] = useState(true);
    
    // Generate a placeholder clip from the current time in the video the user is watching
    const placeholderClip = {
        start: suggestStartTime(),
        startPretty: getPrettyTimestamp(suggestStartTime()),
        end: suggestEndTime(),
        endPretty: getPrettyTimestamp(suggestEndTime()),
        source: currentVideo.url,
        url: currentVideo.url + "#t=" + suggestStartTime(),
        valid: !!currentVideo.url
    };

    function suggestStartTime() {
        if (currentVideo.time === null || !currentVideo.url) {
            return undefined;
        }
        // current video time + 1 to prevent the creation of clips that are less than a second long
        if (parseFloat(currentVideo.time) + 1 < currentVideo.duration) {
            return currentVideo.time;
        }
        else {
            return parseFloat(currentVideo.duration) - 10;
        }
    }

    function suggestEndTime() {
        if (currentVideo.time === null|| !currentVideo.url) {
            return undefined;
        }
        else if (parseFloat(currentVideo.time) + 10 < currentVideo.duration) {
            return parseFloat(currentVideo.time) + 10;
        }
        else {
            return currentVideo.duration;
        }
    }

    useEffect(() => {
        // Using a timeout to limit the amount of retries per second
        setTimeout(() => {
            // Check if there already is a valid clip attached to the comment. If not, take the suggested default clip.
            if (
                !currentClip ||
                (currentClip && currentClip?.source !== placeholderClip.source) ||
                (currentClip && isNaN(currentClip?.start)) ||
                (currentClip && isNaN(currentClip?.end))
            ) {
                console.log("No clip or faulty clip:", currentClip);
                onChangeValue(placeholderClip);
            }
        }, 500);
    }, [currentVideo])
    

    const clip = currentClip || placeholderClip;

    function validateClipData(startPretty, endPretty) {
        let start, end;

        // Validate timestamp formatting
        let startValid = validateTimestamp(startPretty);

        if (startValid) {
            start = getSecondsFromPrettyTimestamp(startPretty);

            // Check if the timestamp lies within the video
            startValid = (start < currentVideo.duration);
        }

        // setState doesn't take effect immediately so we're using a second variable to store the result
        setStartValidState(startValid);

        let endValid = validateTimestamp(endPretty);

        if (endValid) {
            end = getSecondsFromPrettyTimestamp(endPretty);
            endValid = (end <= currentVideo.duration);
            // Be aware that the ZDF video player UI rounds up to the next full second, so if a video is shown to end at 43:59 and the user enters 43:59 as the end of their clip, it might actually be off by a few milliseconds and therefore invalid.
        }

        setEndValidState(endValid);

        // Validate clip length: cannot be longer than 60 seconds, start needs to be before end
        const lengthValid = (start < end && (start + 60) >= end);

        if (startValid && endValid && lengthValid) {
            // If everything is valid, update the clip
            onChangeValue({ start, startPretty, end, endPretty, source: clip.source, url: clip.source + "#t=" + start, valid: true });
        }
        else {
            // Mark both text fields as invalid if the clip length is incorrect
            if (!lengthValid) {
                setStartValidState(false);
                setEndValidState(false);
            }

            // Leave start and end as they are but update startPretty and endPretty to display what the user has entered
            onChangeValue({ start: clip.start, startPretty, end: clip.end, endPretty, source: clip.source, url: clip.source + "#t=" + clip.start, valid: false });
        }
    }

    return <View>
        <ClipPlayer clip={clip}/>
        <div style={ClipEditorStyle.timestampbar}>
            <div style={ClipEditorStyle.timestamp}>
                <UtilityText text={"Start Time"}/>
                <ValidatedTextField
                    value={clip.startPretty}
                    onChange={startPretty => { validateClipData(startPretty, clip.endPretty); }}
                    placeholder={"hh:mm:ss"}
                    big={false}
                    backgroundColor={"#fcebeb"}
                    valid={clip.startPretty ? startValidState : false} />
            </div>
            <div style={ClipEditorStyle.timestamp}>
                <UtilityText text={"End Time"}/>
                <ValidatedTextField
                    value={clip.endPretty}
                    onChange={endPretty => { validateClipData(clip.startPretty, endPretty); }}
                    placeholder={"hh:mm:ss"}
                    big={false}
                    valid={clip.endPretty ? endValidState : false} />
            </div>
        </div>
        <div style={{height: "10px"}}/>
        <UtilityText text="MAX. LENGTH: 1 MINUTE" weight='medium' type="tiny"/>
    </View>
}

const ClipEditorStyle = StyleSheet.create({
    timestampbar: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
        width: "543px"
    },
    timestamp: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start"
    }
})