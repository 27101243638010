import { HorizBox, Pad, PadBox, Separator } from "np-platform-client/component/basics";
import { TextField, Heading, UtilityText } from "np-platform-client/component/text";
import { CTAButton, TextButton } from "np-platform-client/component/button";
import React, { useState, useEffect } from "react";
import { callServerApiAsync } from "np-platform-client/system/servercall";
import { useDatastore } from "np-platform-client/util/datastore";
import { View, StyleSheet, ScrollView, ActivityIndicator, Dimensions, Pressable } from "react-native";
import { ArrowUpRight } from "@carbon/icons-react";
import { useHasCapability } from "np-platform-client/component/admin";
import { colorBlack, colorGreen, colorGreyBorder, colorLightBlueBackground, colorRed, colorTextBlue, colorTextGrey } from "np-platform-client/component/color";
import { Modal } from "np-platform-client/component/modal";
import { logEventAsync } from "np-platform-client/util/eventlog";

export const BlacklistEditorFeature = {
    name: "Blacklist Editor",
    key: "blacklisteditor",
    subscreens: {
        blacklist: () => <BlacklistEditorScreen />,
    },
    config: {
        pageTopWidgets: [OpenBlacklistButton],
    },
};

function OpenBlacklistButton() {
    const hasCapability = useHasCapability("blacklist/edit"); //TODO: Figure out roles / capabilites
    const datastore = useDatastore();
    if (!hasCapability) {
        return null;
    }

    const openBlacklist = () => {
        datastore.pushSubscreen("blacklist");
    };
    return (
        <HorizBox>
            <ArrowUpRight />
            <Pad size={5} />
            <TextButton label={"Blacklist"} type="small" underline={true} onPress={openBlacklist} />
        </HorizBox>
    );
}

const BlacklistFeedbackType = {
    Loading: "loading",
    Success: "success",
    Failure: "failure",
}

export function BlacklistEditorScreen() {
    const datastore = useDatastore();
    const [newTerms, setNewTerms] = useState("");
    const [blacklistData, setBlacklistData] = useState([]);
    const [selectedTerms, setSelectedTerms] = useState([]);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [feedbackType, setFeedbackType] = useState(null);
    const [feedbackMessage, setFeedbackMessage] = useState(null);
    
    const windowHeight = Dimensions.get("window").height;

    const s = BlacklistEditorScreenStyle;

    const maxHeight = windowHeight * 0.75;

    useEffect(() => {
        const fetchBlacklist = async () => {
            const blacklistResult = await datastore.callServerAsync("moderationZdf", "getBlacklist", {});
            if (blacklistResult.length > 0) {
                setBlacklistData(blacklistResult.map((term) => term.toLowerCase()).sort());
            }
        };
        fetchBlacklist();
    }, []);

    const handleAddTerms = async ({ terms }) => {
        const termsArray = terms
            .toLowerCase()
            .split(/\n/)
            .flatMap((line) =>
                line
                    .split("\t")
                    .map((term) => term.trim())
                    .filter((term) => term !== "")
            );

        if (termsArray.length === 0) {
            return;
        }

        const uniqueTerms = Array.from(new Set(termsArray));
        const existingTerms = uniqueTerms.filter((term) => blacklistData.includes(term));
        const newTerms = uniqueTerms.filter((term) => !blacklistData.includes(term));

        if (existingTerms.length === termsArray.length || newTerms.length === 0) {
            setFeedbackType(BlacklistFeedbackType.Failure);
            setFeedbackMessage("Term(s) are already in blacklist");

            setTimeout(() => {
                setFeedbackType(null);
            }, 2000);
            return;
        }

        setFeedbackType(BlacklistFeedbackType.Loading);
        setFeedbackMessage("Adding term(s)...");

        await callServerApiAsync({
            datastore,
            component: "moderationZdf",
            funcname: "addToBlacklist",
            params: { terms: newTerms },
        });

        logEventAsync(datastore, 'blacklist-add-terms', { terms: newTerms.join("\n") });

        const updatedList = [...blacklistData, ...newTerms].sort();
        setBlacklistData(updatedList);
        setNewTerms("");
        setFeedbackType(BlacklistFeedbackType.Success);
        setFeedbackMessage("Added term(s) successfully!");

        setTimeout(() => {
            setFeedbackType(null);
        }, 2000);
    };

    const handleSelectTerm = (term, index, shiftPressed) => {
        const isTermSelected = selectedTerms.includes(term);
        if (shiftPressed && lastSelectedIndex !== null) {
            const rangeStart = Math.min(lastSelectedIndex, index);
            const rangeEnd = Math.max(lastSelectedIndex, index);

            setSelectedTerms((prev) => {
                const newSelectedTerms = new Set(prev);
                for (let i = rangeStart; i <= rangeEnd; i++) {
                    const termToSelect = blacklistData[i];
                    if (isTermSelected) {
                        newSelectedTerms.delete(termToSelect);
                    } else {
                        newSelectedTerms.add(termToSelect);
                    }
                }
                return Array.from(newSelectedTerms);
            });
        } else {
            setSelectedTerms((prev) => {
                const newSelectedTerms = new Set(prev);
                if (isTermSelected) {
                    newSelectedTerms.delete(term);
                } else {
                    newSelectedTerms.add(term);
                }
                return Array.from(newSelectedTerms);
            });
        }
        setLastSelectedIndex(index);
    };

    const handleDeleteTerm = async () => {
        setFeedbackType(BlacklistFeedbackType.Loading);
        setFeedbackMessage("Deleting term(s)...");

        const updatedBlacklist = await callServerApiAsync({
            datastore,
            component: "moderationZdf",
            funcname: "removeFromBlacklist",
            params: { terms: selectedTerms },
        });

        logEventAsync(datastore, 'blacklist-delete-terms', { terms: selectedTerms.join("\n") });

        if (updatedBlacklist) {
            setBlacklistData(updatedBlacklist.sort());
        }

        setSelectedTerms([]);
        setShowDeleteConfirm(false);
        setFeedbackType(BlacklistFeedbackType.Success);
        setFeedbackMessage("Deleted term(s) successfully!");

        setTimeout(() => {
            setFeedbackType(null);
        }, 2000);
    };

    return (
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
            <View style={{ marginTop: 10 }}>
                <Heading label="Blacklisted Terms" level={1} center={true} />
            </View>

            <View style={{ marginTop: 10 }}>
                <Heading
                    label="Hold Shift and double-click to select or deselect multiple terms."
                    level={4}
                    center={true}
                />
                <Pad size={10} />
                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                    <View style={{ flexGrow: 1, marginRight: 10 }}>
                        <TextField
                            value={newTerms}
                            onChange={(text) => {
                                setNewTerms(text);
                            }}
                            placeholder="Enter new terms, separated by new lines"
                            multiline
                            editable={feedbackType !== BlacklistFeedbackType.Loading}
                        />
                    </View>
                    <CTAButton
                        label="Add to blacklist"
                        onPress={() => handleAddTerms({ terms: newTerms })}
                        disabled={feedbackType === BlacklistFeedbackType.Loading}
                    />
                </View>
            </View>

            <View style={{ flex: 1 }}>
                {feedbackType !== null ? (
                    <View style={{ height: 30 }}>
                        {feedbackType === BlacklistFeedbackType.Loading && (
                            <SystemFeedbackMessage message={feedbackMessage} color={colorTextBlue} showLoadingSymbol={true} />
                        )}

                        {feedbackType === BlacklistFeedbackType.Success && (
                            <SystemFeedbackMessage message={feedbackMessage} color={colorGreen} />
                        )}

                        {feedbackType === BlacklistFeedbackType.Failure && (
                            <SystemFeedbackMessage message={feedbackMessage} color={colorRed} />
                        )}
                    </View>
                ) : (
                    <View style={{ height: 30 }} />
                )}

                <ScrollView style={{ maxHeight: maxHeight, flexGrow: 1 }}>
                    <ListOfBlacklistTerms
                        blacklistTerms={blacklistData}
                        selectedTerms={selectedTerms}
                        onSelectTerm={handleSelectTerm}
                    />
                </ScrollView>
            </View>

            <View style={s.actionButtonsContainer}>
                {selectedTerms.length > 0 && (
                    <View style={{ marginTop: 20 }}>
                        <CTAButton label="Delete selected" onPress={() => setShowDeleteConfirm(true)} />
                    </View>
                )}
            </View>

            {showDeleteConfirm && (
                <DeleteBlacklistTermModal
                    onClose={() => setShowDeleteConfirm(false)}
                    onCancel={() => setShowDeleteConfirm(false)}
                    onConfirm={handleDeleteTerm}
                />
            )}
        </View>
    );
}

const BlacklistEditorScreenStyle = StyleSheet.create({
    loadingContainer: {
        flexDirection: "row",
        alignItems: "center",
        marginTop: 10,
    },
    actionButtonsContainer: {
        paddingVertical: 10,
        paddingHorizontal: 10,
        justifyContent: "flex-end",
        marginBottom: 20,
    },
});

function DeleteBlacklistTermModal({ onClose, onCancel, onConfirm }) {
    const s = StyleSheet.create({
        modalButtonContainer: {
            flexDirection: "row",
            justifyContent: "space-between",
        }
    });

    return (
        <Modal
            onClose={onClose}
            buttonRow={
                <View style={s.modalButtonContainer}>
                    <CTAButton type="delete" label="Cancel" onPress={onCancel} />
                    <CTAButton label="Delete" onPress={onConfirm} />
                </View>
            }
        >
            <PadBox horiz={20} vert={20}>
                <Heading label="Delete terms?" level={2} />
                <Pad size={16} />
                <Heading
                    label="Do you really want to delete the selected term(s)? This action cannot be undone."
                    level={4}
                />
            </PadBox>
        </Modal>
    );
}

export const ListOfBlacklistTerms = React.memo(function renderList({ blacklistTerms, selectedTerms, onSelectTerm }) {
    const s = ListOfBlacklistTermsStyle;

    const handleSelect = (term, index, event) => {
        const shiftPressed = event.shiftKey;
        onSelectTerm(term, index, shiftPressed);
    };

    return (
        <>
            {blacklistTerms.length === 0 ? (
                <Heading label="Blacklist is empty." level={4} />
            ) : (
                blacklistTerms.map((term, index) => {
                    const isSelected = selectedTerms.includes(term);
                    return (
                        <Pressable
                            key={index}
                            style={[s.tableRow, isSelected && s.selectedTerm]}
                            onPress={(event) => handleSelect(term, index, event)}
                        >
                            <UtilityText text={term} color={isSelected ? colorTextBlue : colorBlack} />
                            <UtilityText
                                label={isSelected ? "Deselect" : "Select"}
                                level={5}
                                color={isSelected ? colorTextBlue : colorTextGrey}
                            />
                        </Pressable>
                    );
                })
            )}
        </>
    );
});

const ListOfBlacklistTermsStyle = StyleSheet.create({
    tableRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: colorGreyBorder,
    },
    selectedTerm: {
        backgroundColor: colorLightBlueBackground,
    },
});

export function SystemFeedbackMessage({ message, color = colorTextGrey, showLoadingSymbol = false }) {
    const style = {
        flexDirection: "row",
        alignItems: "center",
        gap: 10,
        height: 30,
    };

    return (
        <View style={style}>
            {showLoadingSymbol && <ActivityIndicator size="small" color={color} />}
            <Heading label={message} level={4} center={false} color={color} />
        </View>
    );
}
