import { QuestionStructure } from "./question";
import { ModerationDashStructure } from "./moderationdash";
import { ArticleStructure } from "./article";
import { OldTopicStructure } from "./cbcrc/topic/topic";
import { VideoVotingStructure } from "../contrib/zdf/structure/videovoting";
import { TopicStructure } from "./topic";

export const newStructures = [
    QuestionStructure,
    ModerationDashStructure,
    ArticleStructure,
    VideoVotingStructure,
    OldTopicStructure,
    TopicStructure
]

