import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { useCollection, useDatastore } from "np-platform-client/util/datastore";
import { useConfig } from "np-platform-client/util/features";
import { HorizBox, PadBox } from "np-platform-client/component/basics";
import { Catcher, CatchList } from "np-platform-client/system/catcher";
import { colorTeaserBackground, colorLightBlueBackground } from "np-platform-client/component/color";
import { Comment } from "np-platform-client/component/comment";
import { RichText } from "np-platform-client/component/richtext";
import { Banner } from "np-platform-client/component/banner";
import { Heading } from "np-platform-client/component/text";
import { DropDownSelector } from "np-platform-client/component/button";

function filterComments({datastore, comments, commentFilters}) {
    if (commentFilters) {
        return comments.filter(comment =>
            commentFilters.every(filter => filter({datastore, comment}))
        )
    } else {
        return comments;
    }
}

export function REPLACE_CBC_PopularComments({about=null, showInput=true, canPost=true}) {
    const datastore = useDatastore();
    const {noCommentsMessage} = useConfig();
    const {commentFilters} = useConfig();
    const comments = useCollection('comment', {filter: {about, replyTo: null}, sortBy: 'time', reverse: true})?.slice(0,4);
    const filteredComments = filterComments({datastore, comments, commentFilters});
    // TODO filter and only display popular comments (most upVotes or reactions?)
    return (
        <View>
            {comments?.length == 0 &&
                <PadBox vert={20} horiz={20}><Banner color={colorTeaserBackground}><RichText label={noCommentsMessage} /></Banner></PadBox>
            }
            <CatchList items={filteredComments} renderItem={comment => <Comment commentKey={comment.key} />} />
        </View>
    )
}

export function REPLACE_CBC_CommentsWithSort({about=null, showInput=true, canPost=true, headingLabel = ''}) {
    const datastore = useDatastore();
    const {noCommentsMessage, noMoreCommentsMessage} = useConfig();
    const {commentInputPlaceholder, commentInputLoginAction, pageTopWidgets, commentFilters} = useConfig();
    const comments = useCollection('comment', {filter: {about, replyTo: null}, sortBy: 'time', reverse: true});
    const filteredComments = filterComments({datastore, comments, commentFilters});
    const [stateComments, setStateComments] = useState(filteredComments);
    const [dropDownValue, setDropDownValue] = useState('recent');

    useEffect(() => {
        if (dropDownValue === 'recent') {
            setStateComments(stateComments.sort((x, y) => x.time - y.time));
            return;
        }
        if (dropDownValue === 'oldest') {
            setStateComments(stateComments.sort((x, y) => x.time - y.time).reverse());
            return;
        }
    }, [dropDownValue])

    if (stateComments === null) return null;

    return (
        <View>
            <HorizBox spread>
                <Heading level={2} label={headingLabel} />
                <DropDownSelector label='Sort by'
                    value={dropDownValue} onChange={setDropDownValue}
                    options={[
                        {key: 'recent', label: 'Most recent'},
                        {key: 'oldest', label: 'Oldest'}
                ]} />
            </HorizBox>
            <PadBox vert={32} />
            <View>
                {pageTopWidgets?.map((Widget,i) =>
                    <Catcher key={i}><Widget comments={comments} /></Catcher>
                )}
            </View>
            {comments?.length == 0 &&
                <PadBox vert={20} horiz={20}><Banner color={colorTeaserBackground}><RichText label={noCommentsMessage} /></Banner></PadBox>
            }
            <CatchList items={stateComments} renderItem={comment => <Comment commentKey={comment.key} />} />
            {comments?.length > 1 &&
                <PadBox vert={40} horiz={20}><Banner color={colorLightBlueBackground}><RichText label={noMoreCommentsMessage} /></Banner></PadBox>
            }
        </View>
    )
}