import { CLICK } from "np-platform-client/component/demo";
import { ComposerEntryPointCard, PostedCommentEntryPointCard } from "../feature/question/ContextEntryPointsFeature";
import { makeAssetUrl } from 'np-platform-client/util/util';
import React, { useState } from "react"; 
import { ParamContext } from "np-platform-client/util/params";

const pastArticleTitle = 'Title of article that the user already read in the past';
const composerArticleTitle = 'Title of article that the user was reading when they visited the composer just now';

const initialComment = {
    entryPoints: [
        {
            title: pastArticleTitle, 
            image: makeAssetUrl("images/bubbles.png"), 
            url: makeAssetUrl("images/bubbles.png"), 
            isClosed: false
        },
    ]
};

const mockGlobalParams = {
    entryTitle: composerArticleTitle,
    entryImage: makeAssetUrl("images/bubbles.png"),
    entryUrl: makeAssetUrl("images/bubbles.png"),
};

// Conditionally mock window.open if it's not available in the test environment
// 'Read Past Article' clicks on LinkText, which opens in a new window
function mockWindowOpen() {
    if (typeof jest !== 'undefined' && typeof jest.fn === 'function') {
        window.open = jest.fn();
    }
}

function ComposerContextEntryPointWrapper() {
    const [comment, setComment] = useState(initialComment);
    return <ParamContext.Provider value={{ globalParams: mockGlobalParams }}>
            <ComposerEntryPointCard comment={comment} setComment={setComment} />
        </ParamContext.Provider>
}

function PostedContextEntryPointWrapper() {
    mockWindowOpen();
    const [comment, setComment] = useState(initialComment);
    return <ParamContext.Provider value={{ globalParams: mockGlobalParams }}>
            <PostedCommentEntryPointCard comment={comment} />
        </ParamContext.Provider>
}

export function ContextEntryStorySets() {
    return [
        {
            label: 'Composer View',
            content: <ComposerContextEntryPointWrapper />,
            stories: [
                {
                    label: 'Close Latest Article',
                    actions: [
                        CLICK(`Close ${composerArticleTitle}`)
                    ]
                },
                {
                    label: 'Close Past Article',
                    actions: [
                        CLICK(`Close ${pastArticleTitle}`)
                    ]
                }
            ],
        },
        {
            label: 'Posted Comment View',
            content: <PostedContextEntryPointWrapper />,
            stories: []
            // Click test actions don't work on links.
            // TODO: maybe some other way of testing links
            // stories: [
            //     {
            //         label: 'Read Past Article',
            //         actions: [
            //             CLICK(`Read ${pastArticleTitle}`)
            //         ]
            //     }
            // ],
        },        
    ];
}
