import React, { createContext, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { pauseVideo, playVideo, usePlayedVideo } from "../../component/videotime";

/**
 * @typedef {Object} VideoPlayerState
 * @property {boolean} isPlaying - Indicates if the video is currently playing.
 * @property {Function} playVideo - Function to start the video playback.
 * @property {Function} pauseVideo - Function to pause the video playback.
 * @property {number} currentTime - The current playback time of the video in seconds.
 * @property {number} duration - The total duration of the video in seconds.
 * @property {boolean} ended - Whether the video has ended
 * @property {React.MutableRefObject} videoRef - Reference to the video element.
 * @property {string} url - The URL of the video.
 * @property {Function} attachEventListeners - Function to initialize and attach event listeners to the video element.
 * @property {Function} videoLoaded - Indicates whether the video object is successfully loaded.
 */


// Custom VideoContext. It should provide all necessary data from a played video
export const VideoPlayerStateContext = createContext({})
/**
 * Context provider for controlling any video state.
 * Any <video> element can be the source, by assigning the videoRef from the useVideo hook.
 * Setting useVideoFromArticle enables the control of the main video of the website instead.
 */
export function VideoPlayerStateContextProvider({children, useVideoFromArticle=true}) {

    // Controls for an own video
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [ended, setEnded] = useState(false);
    const videoRef = useRef();

    // Controls for the the original video
    const playerOnArticleSite = usePlayedVideo();
    useEffect(()=>{
        if(useVideoFromArticle && playerOnArticleSite.ready){
            setVideoLoaded(true)
        }
    },[playerOnArticleSite.ready])

    useEffect(()=>{
        if(useVideoFromArticle && playerOnArticleSite.ended){
            setEnded(true)
        }
    },[playerOnArticleSite.ended])

    const play = () => {
        if(useVideoFromArticle){
            playVideo()
        } else {
            videoRef.current.play()
        }  
    }

    const pause = () => {
        if(useVideoFromArticle){
            pauseVideo()
        } else {
            videoRef.current.pause()
        } 
    }

    // Attach Event listeners to the assigned ref
    // Required initial call when using own video element instead of the og video
    const attachEventListeners = () => {
        const videoElement = videoRef.current;

        const handlePlay = () => {
            setIsPlaying(true);
        };
        const handlePause = () => {
            setIsPlaying(false);
        };
        const handleTimeUpdate = () => {
            setCurrentTime(videoElement.currentTime);
        };
        const handleDurationChange = () => {
            setDuration(videoElement.duration);
        };
        const handleEnded = () => {
            setEnded(true)
        };

        if (videoElement) {
            videoElement.addEventListener('play', handlePlay);
            videoElement.addEventListener('pause', handlePause);
            videoElement.addEventListener('timeupdate', handleTimeUpdate);
            videoElement.addEventListener('durationchange', handleDurationChange);
            videoElement.addEventListener('ended', handleEnded);
        }

        setVideoLoaded(true)

    };

    let playerInfo;
    if(useVideoFromArticle) {
        playerInfo = {
            isPlaying: !playerOnArticleSite.paused,
            playVideo: play,
            pauseVideo: pause,
            currentTime: playerOnArticleSite.time,
            duration: playerOnArticleSite.duration,
            videoRef: videoRef,
            url: playerOnArticleSite.url,
            attachEventListeners: attachEventListeners,
            videoLoaded: videoLoaded,
            ended: playerOnArticleSite.ended
        }
    } else {
        playerInfo = {
            isPlaying: isPlaying,
            playVideo: play,
            pauseVideo: pause,
            currentTime: currentTime,
            duration: duration,
            videoRef: videoRef,
            url: videoRef?.current?.src,
            attachEventListeners: attachEventListeners,
            videoLoaded: videoLoaded,
            ended: ended
        }
    }

    useEffect(() => {
        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('play');
                videoRef.current.removeEventListener('pause');
                videoRef.current.removeEventListener('timeupdate');
                videoRef.current.removeEventListener('durationchange');
                videoRef.current.removeEventListener('ended');
            }
        };
    },[])

    return <VideoPlayerStateContext.Provider id={"VideoPlayerStateContextProvider"} value={playerInfo}>
        {children}
    </VideoPlayerStateContext.Provider>
}

/**
 * Custom hook to access the video player state and actions.
 *
 * @returns {VideoPlayerState} The current state and actions of the video player.
 * @throws {Error} If the hook is not used within a VideoPlayerStateContextProvider.
 */
export const useVideo = () => {
    const context = useContext(VideoPlayerStateContext)
    if (!context) {
        throw new Error('useVideo must be used within a VideoPlayerStateContextProvider');
    }
    return context;
};