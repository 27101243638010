import React, { useEffect } from 'react';
import { newStructures } from './structure';
import { useLiveUrl } from 'np-platform-client/util/navigate';
import { getScreenStackForUrl, gotoInstance } from 'np-platform-client/util/navigate';
import { addStructures } from 'np-platform-client/structure';
import { addTranslations } from 'np-platform-client/translations';
import { newTranslations } from './translations';
import { ScreenStack, getStructureForKey, useStandardFonts } from 'np-platform-client/util/instance';
import { addDefaultFeatures, addFeatures } from 'np-platform-client/feature';
import { newDefaultFeatures, newFeatures, newRoles } from './feature';
import { addEventTypes } from 'np-platform-client/util/eventlog';
import { newEventTypes } from './util/eventtypes';
import { setFirebaseConfig } from 'np-platform-client/util/firebase';
import { extendRoles } from 'np-platform-client/component/admin';
import { getIsLocalhost } from 'np-platform-client/util/util';
import { Text } from 'react-native';
import { registerLoginProviders } from 'np-platform-client/structure/login';
import { githubLogin, rcIntLogin, rcLogin } from 'np-platform-client/util/loginproviders';

const defaultFirebaseConfig = {
    apiKey: "AIzaSyDIg3OR3i51VYrUyUd_L5iIownjdSnExlc",
    authDomain: "np-psi-dev.firebaseapp.com",
    databaseURL: "https://np-psi-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "np-psi-dev",
    storageBucket: "np-psi-dev.appspot.com",
    messagingSenderId: "768032889623",
    appId: "1:768032889623:web:634a1604eda03820ab7552"
};

try {
    const config = require('./production-config');
    setFirebaseConfig(config.firebaseConfig);
    console.log('Using production config');
} catch (e) {
    console.warn('No production config. Using default.');
    console.warn('See https://github.com/wearenewpublic/psi-product/blob/main/docs/deploy.md for more information.')
    setFirebaseConfig(defaultFirebaseConfig);
}

addStructures(newStructures);
addTranslations(newTranslations);
addFeatures(newFeatures);
addDefaultFeatures(newDefaultFeatures);
addEventTypes(newEventTypes);
extendRoles(newRoles);
registerLoginProviders([githubLogin, rcIntLogin, rcLogin]);

export default function App() {
    useStandardFonts();
    const url = useLiveUrl();
    const {siloKey, structureKey, instanceKey, screenStack} = getScreenStackForUrl(url);
    const structure = getStructureForKey(structureKey);

    console.log('App', {url, siloKey, structureKey, instanceKey, screenStack, structure});

    useEffect(() => {
        if (getIsLocalhost() && !structureKey) {
            gotoInstance({structureKey: 'admin', instanceKey: 'one'});
        }
    }, [url]);

    if (!structureKey) {
        return <Error message='You need a valid space URL to see a space' />
    } else if (!structure) {
        return <Error message={'Unknown structure: ' + structureKey} />
    } else if (!instanceKey) {
        return <Error message='Missing instance key' />
    } else {
        return <ScreenStack url={url} screenStack={screenStack} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey} />
    }
}

function Error({message}) {
    return <Text>{message}</Text>
}
