import { Narrow, Pad, PadBox } from "np-platform-client/component/basics";
import { DemoHeader } from "np-platform-client/component/demo";
import { TabBar, TabContent, TabNavigation } from "../../contrib/zdf/tabnavigation";
import { UtilityText } from "np-platform-client/component/text";


export function TabNavigationDemo() {
    const tabs = [
        {
            id: "tab-1",
            label: "Tab 1",
            component: Tab1,
        },
        {
            id: "tab-2",
            label: "Tab 2",
            component: Tab2
        },
    ];

    return (
        <Narrow>
            <DemoHeader label="Tab Navigation" />
            <TabNavigation tabs={tabs} initialTabId={"tab-1"}>
                <PadBox horiz={20}>
                    <TabBar />
                    <Pad/>
                    <TabContent />
                </PadBox>
            </TabNavigation>
        </Narrow>
    );
}

function Tab1() {
    return (
        <UtilityText label="This is the first tab."/>
    );
}

function Tab2() {
    return (
        <UtilityText label="This is the second tab."/>
    );
}