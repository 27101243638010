import { HorizBox, PadBox } from "np-platform-client/component/basics"
import { UtilityText } from "np-platform-client/component/text"
import React from "react"
import { View } from "react-native"
import { PercentageBarContainer, PercentageSegment } from "./videovotingPercentageBars"

export function ColorLegend({labels, colors}) {
    return <HorizBox>
        {labels.map((label, index) => (
            <React.Fragment key={index}>
                <LegendElement label={label} color={colors[index]}></LegendElement>
                <PadBox horiz={24}></PadBox>
            </React.Fragment>   
        ))}
    </HorizBox>
}

export function LegendElement({color, label}){
    return <HorizBox>
        <View style={{width:20, height:20, backgroundColor: color}}></View>
        <PadBox horiz={8}></PadBox>
        <UtilityText text= {label}></UtilityText>
    </HorizBox>
}


export function CountScale({maxValue, maxSteps=5}) {

    if (maxValue < maxSteps) maxSteps = maxValue;
    const stepSize = Math.floor(maxValue / maxSteps);
    const steps = [];
    for (let i = 1; i <= maxSteps; i++) {
        steps.push(Math.round(i * stepSize));
    }

    return (
        <HorizBox>
            <View style={{ width: 56 }} />
            <View style={{ flex: 1 }}>
                <PercentageBarContainer>
                    {steps.map((step, i) => (
                        <PercentageSegment key={i} percentage={stepSize}>
                            <View style={{ flexDirection: "row-reverse" }}>
                                <UtilityText type="large" weight="medium" text={step.toString() + "|"}></UtilityText>
                            </View>
                        </PercentageSegment>
                    ))}
                    <PercentageSegment percentage={maxValue - maxSteps * stepSize}></PercentageSegment>
                </PercentageBarContainer>
            </View>
        </HorizBox>
    );
}

