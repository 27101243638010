import { Narrow } from "np-platform-client/component/basics"
import { View } from "react-native"
import { DemoHeader } from "np-platform-client/component/demo"
import { VideoVotingOption } from "../../../contrib/zdf/videovoting/videoVotingQuestionElements"


export function VideoVotingOptionDemo() {
    return <Narrow>
        <View style={{gap:12}}>
            <DemoHeader label={"Video Voting Options"}/>
            <VideoVotingOption
                optionText={"Option without result"}
                votingEnabled
                showResults={false}
            />
            <VideoVotingOption
                optionText={"Option with result (1/10)"}
                voteCount={1}
                allVoteCount={10}
                voterKeys={["A", "b"]}
                votingEnabled
                showResults
            />
            <VideoVotingOption
                optionText={"Selected option with result (5/10)"}
                voteCount={5}
                allVoteCount={10}
                voterKeys={["A", "b"]}
                selected
                votingEnabled
                showResults
            />
            <VideoVotingOption
                optionText={"Option without votes (0/10)"}
                voteCount={0}
                allVoteCount={10}
                voterKeys={[]}
                votingEnabled
                showResults
            />
            <VideoVotingOption
                optionText={"Results without voting option"}
                voteCount={2}
                allVoteCount={10}
                voterKeys={["A", "b"]}
                selected
                showResults
            />
        </View>    
    </Narrow>
}