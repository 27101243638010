import { useDatastore, useModulePublicData } from "np-platform-client/util/datastore";
import { useIsAdmin } from "np-platform-client/component/admin";
import { useEffect, useState } from "react";

export function useModerationQueue({options = {limit: 1000}} = {}) {
    const modObjs = useModulePublicData('moderation', ['queue'], options);
    const keys = Object.keys(modObjs || {});
    return keys.map(key => ({key, ...modObjs[key]})).sort((a, b) => b.time - a.time);
}

export function useModerationAuthors() {
    const queue = useModerationQueue();

    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const [modAuthors, setModAuthors] = useState({}); 

    async function fetchModerationAuthors() {
        const result = await datastore.callServerAsync('moderation', 'getModItemAuthors');
        setModAuthors(result);
    }

    useEffect(() => {
        if (isAdmin) {
            fetchModerationAuthors();
        }
    }, [isAdmin, queue.length]);
    return modAuthors;
}