import { Narrow, Pad } from "np-platform-client/component/basics"
import { DemoSection } from "np-platform-client/component/demo"
import { Paragraph } from "np-platform-client/component/text"
import { Datastore, usePersonaKey } from "np-platform-client/util/datastore"
import { View } from "react-native-web"
import { QuestionResultIndividual } from "../../../contrib/zdf/videovoting/VideoVotingOverviews"
import { QuestionResultConnected } from "../../../feature/zdf/videovoting/VideoVotingOverviewFeature"
import { videoVotingDemoData } from "./videovotingdemo.util"


//  Individual Question

function getExampleVotesForIndivdualQuestion(question, distribution, personalVoteAtIndex) {
    let votes = []
    distribution.forEach((amount, i) => {
        for(let j = 0; j < amount; j++) {
            votes.push({
                questionKey: question.key,
                optionKey: question.options[i].key,
                from: "b"
            })
        }
    })
    return votes
}

export function QuestionResultIndividualDemo() {
    const individualQuestion = videoVotingDemoData.templateIndividual.questions[2]
    const personalVoteIndividual = {from: "a", optionKey: individualQuestion.options[0].key, questionKey:individualQuestion.key}

    return (
        <Narrow>
            <Datastore>
            <Pad />
            <Paragraph
                type="large"
                text="Individual Questions, as opposed to Connected Questions, are questions that get asked once in the video."
            />
            <Pad />

            <DemoSection label={"No votes at all"}>
                <QuestionResultIndividual
                    question={individualQuestion}
                    allVotes={getExampleVotesForIndivdualQuestion(individualQuestion, [0, 0, 0])}
                />
            </DemoSection>

            <DemoSection label={"Normal distribution"}>
                <QuestionResultIndividual
                    question={individualQuestion}
                    allVotes={getExampleVotesForIndivdualQuestion(individualQuestion, [25, 25, 40])}
                />
            </DemoSection>

            <DemoSection label={"One option without votes"}>
                <QuestionResultIndividual
                    question={individualQuestion}
                    allVotes={getExampleVotesForIndivdualQuestion(individualQuestion, [0, 60, 40])}
                />
            </DemoSection>

            <DemoSection label={"Uneven distribution"}>
                <QuestionResultIndividual
                    question={individualQuestion}
                    allVotes={getExampleVotesForIndivdualQuestion(individualQuestion, [1, 50, 49])}
                />
            </DemoSection>

            <DemoSection label={"With user's personal vote"}>
                <QuestionResultIndividual
                    question={individualQuestion}
                    allVotes={[personalVoteIndividual, ...getExampleVotesForIndivdualQuestion(individualQuestion, [20, 50, 20])]}
                />
            </DemoSection>
            </Datastore>
        </Narrow>
    );
}


// Connected Question

function getExampleVotesForConnectedQuestion(distributions) {
    const question = videoVotingDemoData.templateConnected.questions[0]
    let allVotes = []

    distributions.forEach((distribution, d_index) => {
        const subQuestion = videoVotingDemoData.templateConnected.questions[0].subQuestions[d_index]
        distribution.forEach((amount, i) => {
            for(let j = 0; j < amount; j++) {
                allVotes.push({
                    questionKey: subQuestion.key,
                    optionKey: question.options[i].key
            })
            }
        })
    })

    return allVotes;
}

export function QuestionResultConnectedDemo() {
    const question = videoVotingDemoData.templateConnected.questions[0];
    const subQuestion = videoVotingDemoData.templateConnected.questions[0].subQuestions[1];

    const personalVote = {
        questionKey: subQuestion.key,
        optionKey: question.options[1].key,
        from: "a",
    };

    const bigDistribution = getExampleVotesForConnectedQuestion([
        [100, 555, 100],
        [85, 220, 90],
        [90, 0, 80],
        [1, 80, 120],
        [0, 0, 0],
    ]);

    bigDistribution.push(personalVote);

    return (
        <Narrow>
            <Datastore>
            <View style={{ gap: 12 }}>
                <Paragraph
                    type="large"
                    text="Connected Questions are questions that get repeated throughout the video to see how opinions change over time."
                />

                <Pad />

                <DemoSection label="Relative width with total number of votes">
                    <QuestionResultConnected
                        question={question}
                        publicVotes={bigDistribution}
                        absolute={false}
                        showIndividualVotes={false}
                        showTotalVotes={true}
                    />
                </DemoSection>

                <DemoSection label="No headline">
                    <QuestionResultConnected
                        question={question}
                        publicVotes={bigDistribution}
                        absolute={false}
                        showIndividualVotes={false}
                        showTotalVotes={true}
                        noHeadline={true}
                    />
                </DemoSection>

                <DemoSection label="Relative width with number of individual votes">
                    <QuestionResultConnected
                        question={question}
                        publicVotes={bigDistribution}
                        showIndividualVotes={true}
                        showScale={false}
                    />
                </DemoSection>

                <DemoSection label="Absolute width with total number of votes">
                    <QuestionResultConnected
                        question={question}
                        publicVotes={bigDistribution}
                        absolute={true}
                        showIndividualVotes={false}
                        showTotalVotes={true}
                    />
                </DemoSection>

                <DemoSection label="Absolute width with scale relative to option with most votes">
                    <QuestionResultConnected
                        question={question}
                        publicVotes={bigDistribution}
                        showIndividualVotes={true}
                        absolute={true}
                        showScale={true}
                    />
                </DemoSection>
            </View>
            </Datastore>
        </Narrow>
    );
}
