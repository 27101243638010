import { useState, useEffect,useRef } from "react";
import { Animated } from "react-native";

export const useTypewriter = ({ text, timePerCharacterInMs = 25, play=true, callback, nextTypewriter }) => {
    const [displayText, setDisplayText] = useState("");
    const [shouldPlay, setShouldPlay] = useState(false);

    const animatedValue = useRef(new Animated.Value(0)).current
    const wasStopped = useRef(false)


    useEffect(()=> {
        resetTypewriter()
    },[text, timePerCharacterInMs])

    useEffect(() => {
        if (shouldPlay){
            typeCharacters();
        }
    }, [shouldPlay]);

    function typeCharacters() {
        const listener = animatedValue.addListener(({value})=>setDisplayText(text.substring(0,value)))
        setShouldPlay(false)
        wasStopped.current = false

        Animated.timing(animatedValue,{toValue:text.length, duration:timePerCharacterInMs*text.length, useNativeDriver: false}).start(()=>{
            callback && callback();
            (!wasStopped.current && nextTypewriter) && nextTypewriter.playTypewriter()
            animatedValue.removeListener(listener)
        })
    }

    const playTypewriter = () => {
        setShouldPlay(true)
    }

    const resetTypewriter = () => {
        if (timePerCharacterInMs === 0) {
            setDisplayText(text);
            setShouldPlay(false)
        } else {
            setShouldPlay(play)
            setDisplayText("")
            wasStopped.current = true
            animatedValue.stopAnimation()
            animatedValue.setValue(0)
        }
    }

    return {displayText, playTypewriter, resetTypewriter};
};
