import { StyleSheet, View } from "react-native";
import { UtilityText } from "np-platform-client/component/text";
import { colorGreyPopupBackground } from "np-platform-client/component/color";
import { HorizBox, Pad } from "np-platform-client/component/basics";

// A colored ball with a label next to it. Used in Comment Slider and Poll.
// Figma: https://www.figma.com/file/SgBNOg9PPIKIsSMAmU32rK/Comments-Slider---Testing?node-id=648%3A44526
export function ColoredBallPill({label, color, hasBorder=true}) {
    const s = ColoredBallPillStyle;

    return <View style={s.tag}>
        <HorizBox center>
            <ColoredBall color={color} />
            <Pad size={6} />
            <UtilityText weight='medium' type='tiny' label={label} />
        </HorizBox>
  </View>
}

const ColoredBallPillStyle = StyleSheet.create({
    tag: {
        backgroundColor: colorGreyPopupBackground,
        borderRadius: 100,
        paddingHorizontal: 12,
        paddingVertical: 6,
        alignSelf: 'flex-start'
    }
});

export function ColoredBall({color, size=12}) {
    return <View style={{backgroundColor: color, borderRadius: 100, width: size, height: size}} />
}